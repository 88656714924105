<template>
  <div>
    <div
      class="item"
      v-for="(item, index) of data"
      :key="index"
      @click="click(item)"
    >
      <div class="data">
        <div v-show="item.name && item.name.length>0" class="name">{{ item.name }}</div>
        <div>{{ item.email }}</div>
        <div>
          สถานะ: 
          <UserStatus :isEnable="item.user!=null" :is_expired="item.is_expired" :status="item.status"/>
        </div>
      </div>
      <div>
        <img src="@/assets/img/next.svg" alt="" />
      </div>
    </div>
    <div v-show="!(this.data && this.data.length>0)" class="item">
      <div class="nodata">
        ไม่พบผู้ใช้งาน
      </div>
    </div>
  </div>
</template>



<script>
import UserStatus from "./UserStatus";

export default {
  components:{UserStatus},
  props: { 
    data: Array,
    itemClick:Function
  },
  data() {
    return {};
  },
  methods: {
    click(item) {
      this.itemClick && this.itemClick(item)
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  margin-bottom: 10px;
  display: flex;
  padding: 8px 12px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d8d8d8;
  min-height: 40px;
  user-select: none;
  img {
    width: 15px;
  }
  .data>div:not(:last-child){
    margin-bottom: 8px;
  }

  .data{
    >.name{
      font-weight: bold;
    }
  }
  .nodata{
    padding: 12px 0;
  }
}
</style>