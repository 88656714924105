
<template>
  <div class="productPage">
    <div class="head">
      <NavBar />
      <div :class="[{ hide: hideMenu }, 'collapse']">
        <Menu />
      </div>
    </div>
    <div :class="[{ hasFilters: showFilters }, 'productPageContent']">
      <!-- <keep-alive> -->
      <router-view />
      <!-- </keep-alive> -->
    </div>
    <!-- End card wrap -->

    <div class="bottom_menu">
      <div></div>
    </div>
    <ProductModal />
    <DialogAlcohol />
  </div>
</template>

<script>
import NavBar from "./NavBar";
import Menu from "./Menu";
import ProductModal from "./ProductModal";
import { sync, call } from "vuex-pathify";
// import Filters from "../components/Filters";
import DialogAlcohol from "./components/DialogAlcohol";

export default {
  name: "productsPage",
  components: { NavBar, Menu, ProductModal, DialogAlcohol },
  data() {
    return {
      hideMenu: false,
      saveScroll: {},
    };
  },
  computed: {
    ...sync("showProduct/*"),
    showFilters() {
      return this.$route.meta.filters === true;
    },
  },
  watch: {
    // handle state scroll
    // $route(to, from) {
    //   const mainElm = document.querySelector(".productPageContent");
    //   this.saveScroll[from.name] = mainElm.scrollTop;
    //   if (this.saveScroll[to.name]) {
    //     setTimeout(() => {
    //       this.$nextTick(() => {
    //         mainElm.scrollTop = this.saveScroll[to.name];
    //       });
    //     });
    //   } else {
    //     mainElm.scrollTop = 0;
    //   }
    // },
  },
  methods: {
    ...call("cart/*"),
    selectMenu() {},
    updatefilters(val) {
      console.log(val);
    },
  },
  mounted() {
    // hide nav when scroll
    // const elm = document.getElementById("app");
    // let prevScrollpos = 0;
    // elm.onscroll = () => {
    //   if (prevScrollpos - 15 > elm.scrollTop) {
    //     this.hideMenu = false;
    //     prevScrollpos = elm.scrollTop;
    //   } else if (prevScrollpos + 50 < elm.scrollTop) {
    //     this.hideMenu = true;
    //     prevScrollpos = elm.scrollTop;
    //   }
    // };
  },
};
</script>
<style lang="scss" scoped>
.productPage {
  background: #fff;
  min-height: 100%;
  // padding-bottom: 30px;
  position: relative;
  height: 100%;
  // margin-top: -5px;
}

// .head {
//   position: absolute;
//   width: 100%;
//   z-index: 2;
//   top: 0;
//   transition: top 0.3s;
// }

// .head.hide {
//   top: -95px;
// }

.menu {
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 42px;
  transition: top 0.5s;
}

.menu.hide {
  top: -95px;
}

.collapse {
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 38px;
  transition: top 0.3s;
}
.collapse.hide {
  top: -95px;
}

.productPageContent {
  padding: 85px 0 70px 0;
  height: 100%;
  // overflow-y: scroll;
  // height: 90vh;
  &.hasFilters {
    padding: 110px 0 70px 0;
  }
}
</style>
