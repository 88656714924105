<template>
  <div class="importThaibev">
    <div class="nav">
      <div class="navInner">
        <div @click="$router.back()" class="backIcon"></div>
        <!-- <img @click="$router.back()" src="@/assets/img/left-arrow.svg" alt="" /> -->
        <span class="title">นำเข้าสินค้า Thaibev</span>
      </div>
    </div>
    <DefaultDisplayStage :fetching="fetching" :error="error" :nodata="nodata" />
    <Category
      v-if="categoryData.length > 0"
      class="wrapCategory"
      :data="categoryData"
      v-on:click="categoryClick"
    />
    <div style="height: 40px"></div>
  </div>
</template>

<script>
import Category from "@/views/components/Category";
import { GET } from "@/utils/http";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";

export default {
  components: { Category, DefaultDisplayStage },
  data() {
    return {
      categoryData: [
        // {
        //   name: "Chang",
        //   id: "1",
        // },
        // {
        //   name: "ขนมซอง",
        //   id: "2",
        // },
        // {
        //   name: "อาหารกระป๋อง",
        //   id: "3",
        // },
      ],
      fetching: false,
      error: false,
    };
  },
  computed: {
    nodata() {
      return !this.fetching && this.categoryData.length <= 0 && !this.error;
    },
  },
  methods: {
    categoryClick(item) {
      this.$router.push({
        path: `/manage-product/import-thaibev/category/${item.name}/${item.id}`,
      });
    },
    async getCategory() {
      try {
        this.fetching = true;
        const { status, body } = await GET(
          "/api/brands?page=1&limit=100&query=&companyGroup=thaibev"
        );

        if (status !== 200) {
          throw "not ok";
        }
        this.categoryData = body.data;
      } catch (err) {
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
  },
  mounted() {
    this.getCategory();
  },
};
</script>
<style lang="scss" scoped>
.importThaibev {
  // padding-top: 60px;
  background: #fff;
  height: 100%;
}

.nav {
  position: fixed;
  top: 0;
  z-index: 2;
  // background: white;
  width: 100%;
  height: 45px;
  // box-shadow: 0 0 3px rgba(0, 0, 0, 0.137);

  .navInner {
    background: #ffffffc2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 6px;
    border-radius: 7px;
    > img {
      width: 25px;
    }
    .backIcon {
      background-color: var(--primary-color);
      width: 25px;
      height: 25px;
      mask: url(~@/assets/img/left-arrow.svg) no-repeat center / contain;
    }
  }

  .title {
    padding-left: 8px;
  }
}

.wrapCategory {
  padding-top: 60px;
}
</style>