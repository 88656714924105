<template>
  <div class="main">
    <NavbarBack title="ตั้งค่า / เปลี่ยนอีเมล"/>
    <div class="container">
        <div class="title">
            อีเมล*
        </div>
        <input type="text" v-model="email"/>
    </div>

    <div class="bottom">
        <div class="button" @click="onSave">
            บันทึกข้อมูล
        </div>
    </div>
    
  </div>
</template>

<script>

import NavbarBack from "@/components/NavbarBack";
import { GET,POST } from "@/utils/http";

export default {
  components: { NavbarBack},
  data() {
    return {
      email:"",
    };
  },
  methods: {
    async onSave(){
      const reqBody = {
        email: this.email,
      };

      const url = `/api/change-email`;
      
      const { status ,body } = await POST(url, reqBody);

      if(status===422){
        alert(body.message)
      }

      if(status===200){
        this.$router.push({
          path: `/settings/user-setting`,
        });
      }
    },
    async getMyProfile() {
      try {
        window.loading(true);
        const { status,body} = await GET(
          `/api/user-profile`
        );

        if (status !== 200) {
          throw "not ok";
        }
        
        this.email = body.email

      } catch (err) {
        this.error = true;
      } finally {
        window.loading(false);
      }
    },
  },
  mounted() {
    this.getMyProfile()
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding-top: 40px;
  background: #fff;
  height: 100%;
  
}
.description{
    font-style: italic;
    line-height: 28px;
    color:rgb(102, 102, 102);
    margin-bottom: 10px;
    margin-top: 10px;
}
.container{
  padding:  0 16px;
}
.title {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status{
  margin-top: 10px;
  margin-bottom: 10px;

  >b{
    font-weight: bold;
  }
}
input {
  width: 100%;
  height: 42px;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #b9b9b9;
  background: #ffffffbd;

  &:read-only{
    background-color: #f3f3f3ff;
  }
}
.bottom{
    position: absolute;
    width: 100%;
    padding: 0 16px;
    bottom: 24px;
    >.button:not(:last-child){
      margin-bottom: 8px;
    }
}
.button{
    padding: 16px 0;
    text-align: center;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 8px;
    font-weight: bold;

    &.outline{
      border:1px solid #cccccc;
      background-color: #fff;
      color: #434343;
    }
}
</style>