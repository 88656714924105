<template>
  <div class="h100">
    <ProductList
      :products="products"
      :fetching="fetching"
      :error="error"
      :endList="endList"
      :page="page"
      :nodata="nodata"
      :pageSize="pageSize"
      @scrollEnd="getProducts"
    />
  </div>
</template>

<script>
import ProductList from "./ProductList";
import { GET } from "@/utils/http";

export default {
  name: "ProductAll",
  components: { ProductList },
  data() {
    return {
      products: [],
      fetching: false,
      error: false,
      endList: false,
      page: 1,
      pageSize: 10,
    };
  },
  computed: {
    nodata() {
      return !this.fetching && this.products.length <= 0 && !this.error;
    },
  },
  methods: {
    async getProducts() {
      try {
        this.fetching = true;

        const shopId = this.$isSeller ? this.$myShop.id : this.$sellerShop.id;
        const { status, body } = await GET(
          `/api/shops/${shopId}/products?page=${this.page}&limit=${this.pageSize}&query=`
        );

        if (status !== 200) {
          throw "not ok";
        }

        this.products.push(...body.data);

        if (this.page >= body.lastPage) {
          this.endList = true;
        } else {
          this.page++;
        }
      } catch (err) {
        console.error(err);
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
  },
  mounted() {
    this.getProducts();
  },
};
</script>