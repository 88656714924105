<template>
  <div class="productListHorizon" v-if="products.length > 0">
    <div class="title">{{ title }}</div>
    <div class="productList">
      <div
        class="card"
        v-for="(item, index) in products"
        :key="index"
        @click="showProduct(item)"
      >
        <div
          :class="[
            productCompanyGroupName(item),
            { showBrandFooter: item.parentId },
            'imgWrap',
          ]"
        >
          <div v-if="item.addons" class="strip">
            <span>โปรโมชั่น</span>
          </div>
          <img
            v-if="item.productPhotos"
            :src="
              item.productPhotos.length > 0
                ? item.productPhotos[0].url
                : $placeholderImg
            "
          />
          <img v-else :src="$placeholderImg" />
        </div>
        <div class="detailWrap">
          <div>{{ item.name }}</div>
          <!-- <div>{{ item.description }}</div> -->
          <div class="priceWrap">
            <span class="price">
              <span v-if="calStep(item)"
                >{{ calStep(item).min.toLocaleString() }} ~
                {{ calStep(item).max.toLocaleString() }}</span
              >
              <span v-else>{{ item.salePrice.toLocaleString() }}</span>
            </span>
            <span class="unitPrice">บาท</span>
            <span v-if="item.price !== item.salePrice" class="fromPrice"
              >{{ item.price }} <span class="unitPrice">บาท</span></span
            >
            <span class="unit"> / {{ item.unit }}</span>
          </div>
        </div>
        <div v-if="$isBuyer" class="actionContain">
          <div class="actionWrap" v-if="item.product_status !== 'สินค้าหมด'">
            <div class="buyBtn" @click.stop="clickAddToCart({ product: item })">
              เพิ่มสินค้า
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProductListHorizonLoader v-if="fetching" />
    <DefaultDisplayStage :error="error" :nodata="nodata" />
    <AlertAddCart ref="alertAddCart" />
  </div>
</template>

<script>
import DefaultDisplayStage from "@/components/DefaultDisplayStage";
import ProductListHorizonLoader from "@/components/loader/ProductListHorizonLoader";
import { GET } from "@/utils/http";
import { call } from "vuex-pathify";
import AlertAddCart from "@/components/AlertAddCart";
import { calStep } from "@/utils/product";

export default {
  props: { title: String, catId: Number },
  components: { DefaultDisplayStage, ProductListHorizonLoader, AlertAddCart },
  data() {
    return {
      products: [],
      fetching: false,
      error: false,
    };
  },
  computed: {
    nodata() {
      return !this.fetching && this.products.length <= 0 && !this.error;
    },
  },
  methods: {
    calStep: calStep,
    ...call("cart/*"),
    ...call("showProduct/*"),
    async getThaibevBands() {
      try {
        this.fetching = true;
        const { status, body } = await GET(
          "/api/brands?page=1&limit=100&query=&companyGroup=thaibev"
        );
        console.log("getThaibevBands status body", status, body);
        if (status !== 200) {
          throw "not ok";
        }
        this.categories = body.data;
        // this.processData()
      } catch (err) {
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
    async getCategoryProduct() {
      try {
        this.fetching = true;
        // const { status, body } = await GET(
        //   `/api/products?page=1&limit=100&query=&companyGroup=thaibev&brandIds=${this.catId}`
        // );
        const shopId = this.$isSeller ? this.$myShop.id : this.$sellerShop.id;
        const { status, body } = await GET(
          `/api/shops/${shopId}/products?page=1&limit=100&query=&brandIds=${this.catId}`
        );

        if (status !== 200) {
          throw "not ok";
        }

        this.products = body.data || [];
      } catch (err) {
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
    clickAddToCart(data) {
      this.addCart(data);
      this.$refs.alertAddCart.tick();
    },
  },
  mounted() {
    this.getCategoryProduct();
    console.log("mounted cat", this.title, this.catId);
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/productCardListHorizon.scss";
</style>