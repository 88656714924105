<template>
  <span>
    <span v-if="status === 'pending' && is_expired" class="close">คำเชิญหมดอายุ</span>
    <span v-else-if="status === 'pending'" class="wait">รอยืนยันอีเมล</span>
    <span v-else-if="status === 'accept' && isEnable===true" class="open">เปิดใช้งาน</span>
    <span v-else-if="status === 'accept' && isEnable===false" class="close">ปิดการใช้งาน</span>
  </span>
</template>

<script>
export default {
  props: { 
    status: String,
    is_expired:Boolean,
    isEnable:Boolean,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
  .open{
    color:var(--primary-color);
  }
  .close{
    color:#da291c
  }
  .wait{
    color:#f67b00;
  }

</style>