<template>
  <div class="h100">
    <div class="recommend_title">สินค้าโปรโมชั่น</div>
    <RecommendList
      :products="promotion_products"
      :fetching="fetching"
      :error="promotion_error"
      :endList="true"
      :page="page"
      :nodata="no_promotion_products_data"
      :pageSize="pageSize"
     
    />
    <div class="recommend_title">สินค้าขายดี</div>
    <RecommendList 
      :products="best_seller_products"
      :fetching="fetching"
      :err="best_seller_error"
      :page="page"
      :endList="true"
      :nodata="no_best_seller_products_data"
      :pageSize="pageSize"
    />
    <div v-if="promotion_products.length > 0 || best_seller_products.length>0" style="height: 100px"></div>
  </div>
</template>

<script>
import RecommendList from "./components/RecommendList";
// import BestSellerList from "./components/BestSellerList";

import { GET } from "@/utils/http";

export default {
  name: "RecommendProducts",
  components: { RecommendList },
  data() {
    return {
      promotion_products: [],
      best_seller_products:[],
      fetching: false,
      promotion_error: false,
      best_seller_error: false,
      endList: false,
      page: 1,
      pageSize: 1000,
    };
  },
  computed: {
    no_promotion_products_data() {
      return !this.fetching && this.promotion_products.length <= 0 && !this.promotion_error;
    },
    no_best_seller_products_data() {
      return !this.fetching && this.best_seller_products.length <= 0 && !this.best_seller_error;
    },
  },
  methods: {
    async getPromotionProducts() {
      try {
        this.fetching = true;

        const shopId = this.$isSeller ? this.$myShop.id : this.$sellerShop.id;
        const { status, body } = await GET(
          `/api/shops/${shopId}/products?page=${this.page}&limit=${this.pageSize}&addons=true`
        );

        if (status !== 200) {
          throw "not ok";
        }

        this.promotion_products = body.data;
      } catch (err) {
        console.error(err);
        this.promotion_error = true;
      } finally {
        this.fetching = false;
      }
    },
    async getBestSellerProducts() {
      try {
        this.fetching = true;

        const shopId = this.$isSeller ? this.$myShop.id : this.$sellerShop.id;
        const { status, body } = await GET(
          `/api/shops/${shopId}/best_sellers?master=false&month=0&rank=10&IncludeProduct=true`
        );
        
        if (status !== 200) {
          throw "not ok";
        }
        this.best_seller_products = body;
        
      } catch (err) {
        this.best_seller_error = true;
      } finally {
        this.fetching = false;
      }
    },
  },
  mounted() {
    this.getPromotionProducts();
    this.getBestSellerProducts();
  },
};
</script>

<style lang="scss" scoped>
.recommend_title {
  font-size: 20px;
  margin-bottom: 10px;
  padding-left: 10px;
}
</style>