<template>
  <ProductList ref="productList" />
</template>

<script>
import ProductList from "./ProductList";

export default {
  components: { ProductList },
  mounted() {
    console.log("mounted: promotion");
  },
};
</script>