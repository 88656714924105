<template>
  <div class="main">
    <NavbarBack title="แก้ไขผู้ใช้งานหลังบ้าน(พนักงาน)" />
    <div class="container">
      <div class="title">อีเมล*</div>
      <input type="text" v-model="email" readonly />
      <div v-if="status > 0">
        <div class="title mt">ชื่อพนักงาน*</div>
        <input type="text" v-model="name" readonly />

        <div class="title mt">สร้างเมื่อ</div>
        <input type="text" v-model="created_date_text" readonly />
      </div>

      <div v-if="status === 0" class="status">
        <b>สถานะ : </b>
        <UserStatus :status="status" />
      </div>
      <!-- <div v-if="status===0" class="description">
          ส่งอีเมลล่าสุดเมื่อ 29 มี.ค. 2564 17.30 น.
        </div> -->

      <div v-if="status > 0" class="status">
        <b>เปิดใช้งาน </b>
        <SlideSwitch />
      </div>
      <div style="margin-top: 8px">
        <!-- <MenuItem v-if="this.status===`pending`" text="เปลี่ยนอีเมลล์" 
            :onClick="changeEmailClick"/> -->

        <!-- <MenuItem v-if="this.user!=null" text="ลืมรหัสผ่าน" :hideIcon="true"
            :onClick="forgetPasswordClick"/> -->
        <div
          v-if="this.status === `pending`"
          class="button warning"
          @click="cancelInviteClick"
        >
          ยกเลิกคำเชิญ
        </div>
        <div
          v-if="this.user != null"
          class="button warning"
          @click="deleteUserClick"
        >
          ลบผู้ใช้งาน
        </div>
      </div>
    </div>

    <div class="bottom">
      <div v-if="status === 0" class="button">ส่งอีเมลอีกครั้ง</div>
    </div>

    <ModalWarningConfirm
      title="ยืนยันการยกเลิกคำเชิญ"
      :open="showCancelInvite"
      @close="showCancelInvite = false"
      @confirm="confirmCancelInvite"
      :message="`ยกเลิกการเชิญ 
                ${this.email}`"
    />

    <ModalConfirm
      title="ยืนยันการลืมรหัสผ่าน"
      :open="showForgetPassword"
      @close="showForgetPassword = false"
      @confirm="confirmForgetPassword"
      :message="`ลิงก์จะถูกส่งไปยังอีเมล
                ${this.email}
                เพื่อสร้างรหัสผ่านใหม่`"
    />

    <ModalWarningConfirm
      title="ยืนยันการลบผู้ใช้งาน"
      :open="showDeleteUser"
      @close="showDeleteUser = false"
      @confirm="confirmDeleteUser"
      :message="`ลบผู้ใช้งาน ${this.email}`"
    />
  </div>
</template>

<script>
import { POST, GET, DELETE } from "@/utils/http";

import NavbarBack from "@/components/NavbarBack";
import SlideSwitch from "@/components/SlideSwitch";
import ModalConfirm from "@/components/ModalConfirm";
import ModalWarningConfirm from "@/components/ModalWarningConfirm";
import UserStatus from "@/views/components/UserStatus";
import jwtDecode from "jwt-decode";

export default {
  components: {
    NavbarBack,
    UserStatus,
    ModalConfirm,
    ModalWarningConfirm,
    SlideSwitch,
  },
  props: {
    id: String,
  },
  data() {
    return {
      email: "",
      status: "",
      is_expired: false,
      user: null,
      showDeleteUser: false,
      showForgetPassword: false,
      showCancelInvite: false,
    };
  },
  computed: {
    created_date_text() {
      return "29 มี.ค. 2564 16:54";
    },
  },
  methods: {
    changeEmailClick() {
      if (this.status === 0) {
        this.$router.push({
          path: `/settings/user-setting/change-confirm-email`,
        });
      } else {
        this.$router.push({
          path: `/settings/user-setting/change-email`,
        });
      }
    },
    cancelInviteClick() {
      this.showCancelInvite = true;
    },
    forgetPasswordClick() {
      this.showForgetPassword = true;
    },
    deleteUserClick() {
      this.showDeleteUser = true;
    },
    async confirmForgetPassword() {
      const reqBody = {
        email: this.email,
      };

      const url = `/api/forgot-password`;

      const { status } = await POST(url, reqBody);

      this.showForgetPassword = false;

      if (status === 200) {
        this.$router.back();
      } else {
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
      }
    },
    async confirmCancelInvite() {
      const decoded = jwtDecode(window.$apiToken);

      const url = `/api/shops/${decoded.s[0].id}/invites/${this.id}`;

      const { status } = await DELETE(url);

      if (status === 204) {
        this.showCancelInvite = false;
        this.$router.back();
      }
    },
    async confirmDeleteUser() {
      const decoded = jwtDecode(window.$apiToken);

      const url = `/api/shops/${decoded.s[0].id}/invites/${this.id}/user`;

      const { status } = await DELETE(url);

      this.showDeleteUser = false;

      if (status === 204) {
        this.$router.back();
      } else {
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
      }
    },
    async getData() {
      const decoded = jwtDecode(window.$apiToken);

      const { status, body } = await GET(
        `/api/shops/${decoded.s[0].id}/invites/${this.id}`
      );

      if (status !== 200) {
        throw "not ok";
      }

      this.email = body.email;
      this.status = body.status;
      this.is_expired = new Date(body.expiredAt) < new Date();
      this.user = body.shopUser.User;
    },
    initial() {
      this.email = "";
      this.getData();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.initial();
    });
  },
  // mounted() {
  //   this.getData();
  // },
};
</script>
<style lang="scss" scoped>
.main {
  padding-top: 40px;
  background: #fff;
  height: 100%;
}
.description {
  font-style: italic;
  line-height: 28px;
  color: rgb(102, 102, 102);
  margin-bottom: 10px;
  margin-top: -10px;
}
.container {
  padding: 0 16px;
}
.title {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status {
  margin-top: 14px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  > b {
    font-weight: bold;
    margin-right: 6px;
  }
}
input {
  width: 100%;
  height: 42px;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #b9b9b9;
  background: #ffffffbd;

  &:read-only {
    background-color: #f3f3f3ff;
  }
}
.bottom {
  position: absolute;
  width: 100%;
  padding: 0 16px;
  bottom: 24px;
  > .button:not(:last-child) {
    margin-bottom: 8px;
  }
}
.button {
  padding: 16px 0;
  margin-top: 8px;
  text-align: center;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 8px;
  font-weight: bold;

  &.warning {
    background-color: #f43131;
  }
  &.outline {
    border: 1px solid #cccccc;
    background-color: #fff;
    color: #434343;
  }
}
.mt {
  margin-top: 8px;
}
</style>