var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"img-container"},[(item.shopPaymentMethod && 
                item.shopPaymentMethod.paymentMethod && 
                item.shopPaymentMethod.paymentMethod.slug == 'transfer')?_c('img',{attrs:{"src":item.bank && item.bank.logo}}):_c('img',{attrs:{"src":require('@/assets/img/promptpay-logo.png')}})]),_c('div',{staticClass:"data"},[(item.shopPaymentMethod && 
                item.shopPaymentMethod.paymentMethod && 
                item.shopPaymentMethod.paymentMethod.slug == 'transfer')?_c('div',[_c('b',[_vm._v(_vm._s(item.bank && item.bank.name))])]):_c('div',[_c('b',[_vm._v("พร้อมเพย์")])]),(item.shopPaymentMethod && 
                item.shopPaymentMethod.paymentMethod && 
                item.shopPaymentMethod.paymentMethod.slug == 'transfer')?_c('div',[_vm._v(" สาขา "),_c('b',[_vm._v(_vm._s(item.branchName))])]):_vm._e(),_c('div',[_vm._v("ชื่อบัญชี "),_c('b',[_vm._v(_vm._s(item.accountName))])]),_c('div',[_vm._v("เลขที่บัญชี "),_c('b',[_vm._v(_vm._s(item.accountNo))])])]),_c('div',[_c('div',{staticClass:"button",on:{"click":function($event){return _vm.click(item)}}},[_vm._v("แก้ไข")])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }