<template>
  <transition name="slidefade">
    <div class="modal">
      <div class="wrapContent">
        <div class="head">
          <!-- <img
            class="backBtn"
            @click="$router.back()"
            src="@/assets/img/left-arrow.svg"
            alt=""
          /> -->
          <div @click="$router.back()" class="backBtn mask-image"></div>
          <input class="queryInput" type="text" v-model="queryInput" />
          <div :class="[{ disable: fetching }, 'searchBtn']" @click="search()">
            ค้นหา
          </div>
        </div>
        <div class="result">
          <ProductList
            :useModal="false"
            :products="products"
            :fetching="fetching"
            :error="error"
            :endList="endList"
            :page="page"
            :nodata="nodata"
            :pageSize="pageSize"
            @scrollEnd="
              if (products.length > 0) {
                getProducts();
              }
            "
            @clickItem="clickItem"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ProductList from "@/views/productsPage/ProductList";
import { GET } from "@/utils/http";

export default {
  name: "SearchProduct",
  components: { ProductList },
  data() {
    return {
      queryInput: "",
      products: [],
      fetching: false,
      error: false,
      endList: false,
      page: 1,
      pageSize: 5,
    };
  },
  computed: {
    nodata() {
      return !this.fetching && this.products.length <= 0 && !this.error;
    },
  },
  methods: {
    async getProducts() {
      try {
        this.fetching = true;

        const shopId = this.$isSeller ? this.$myShop.id : this.$sellerShop.id;
        const { status, body } = await GET(
          `/api/shops/${shopId}/products?page=${this.page}&limit=${this.pageSize}&query=${this.queryInput}`
        );

        if (status !== 200) {
          throw "not ok";
        }

        this.products.push(...body.data);

        if (this.page >= body.lastPage) {
          this.endList = true;
        } else {
          this.page++;
        }
      } catch (err) {
        console.error(err);
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
    search() {
      this.products = [];
      this.endList = false;
      this.page = 1;
      this.getProducts();
    },
    clickItem(item) {
      console.log(item);
      this.queryInput = "";
      //   this.$emit("result", item);
      this.$router.push(`/products/${item.id}`);
    },
  },
  mounted() {
    this.search();
  },
};
</script>

<style lang="scss" scoped>
/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(255, 255, 255); /* Fallback color */
  box-sizing: border-box;
}

.wrapContent {
  width: 100%;
  height: 100%;
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  width: 100%;
  height: 100%;
}

.backBtn {
  &.mask-image {
    width: 30px;
    height: 30px;
    mask-image: url("~@/assets/img/left-arrow.svg");
  }
}

.queryInput {
  padding: 5px 10px;
}

.head {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  padding: 10px;
  overflow: hidden;
  flex-wrap: nowrap;
  z-index: 10;
}

input {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  margin: 0 10px;
  border: 1px solid #b9b9b9;
  background: #ffffffbd;
}

.searchBtn {
  margin: 0 auto;
  width: 80px;
  display: inline-flex;
  border-radius: 10px;
  justify-content: center;
  background: #ffffffc7;
  align-items: center;
  padding: 2px;
  font-size: 14px;
  /* box-shadow: 0 0px 2px 0 rgb(0 0 0 / 50%); */
  border: 1px solid var(--primary-color);
  height: 40px;
  color: var(--primary-color);
  // font-weight: bold;
  &.disable {
    pointer-events: none;
    background: #69696923;
    box-shadow: none;
    color: #a3a3a3;
  }
  &:active {
    background: #dfdfdf81;
  }
}

.result {
  overflow-y: auto;
  height: 100%;
  padding: 70px 10px 25px 10px;
}
</style>