
<template>
  <div class="settingPage">
    <div :class="'settingPageContent'">
      <!-- <keep-alive> -->
        <router-view />
      <!-- </keep-alive> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "settingPage",
  components: {},
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.settingPage {
  background: #fff;
  min-height: 100%;
  // padding-bottom: 30px;
  position: relative;
  height: 100%;
  // margin-top: -5px;
}

// .head {
//   position: absolute;
//   width: 100%;
//   z-index: 2;
//   top: 0;
//   transition: top 0.3s;
// }

// .head.hide {
//   top: -95px;
// }

.collapse {
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 34px;
  transition: top 0.3s;
}
.collapse.hide {
  top: -95px;
}

.settingPageContent {
  // padding: 85px 0 70px 0;
  height: 100%;
}
</style>
