<template>
  <div class="productStatusTag">
    <div
      v-if="getProductStatusApprove(product.status)"
      :class="getProductStatusApprove(product.status).style"
    >
      {{ getProductStatusApprove(product.status).name }}
    </div>
    <div
      v-if="getProductStatus(product.product_status)"
      :class="getProductStatus(product.product_status).style"
    >
      {{ getProductStatus(product.product_status).name }}
    </div>
  </div>
</template>

<script>
import { listProductStatus, listProductStatusApprove } from "@/utils/product";

export default {
  props: { product: Object },
  methods: {
    getProductStatus(name) {
      return listProductStatus().find((e) => e.name === name);
    },
    getProductStatusApprove(name) {
      return listProductStatusApprove().find((e) => e.name === name);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/productStatusTag.scss";
</style>