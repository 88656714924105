<template>
  <div class="thaibev h100">
    <ManageProductListHorizon
      v-for="cat in categories"
      :key="cat.id"
      :title="cat.name"
      :catId="cat.id"
    />
    <DefaultDisplayStage :fetching="fetching" :error="error" :nodata="nodata" />
    <div v-if="categories.length > 0" style="height: 100px"></div>
  </div>
</template>

<script>
import ManageProductListHorizon from "./ManageProductListHorizon";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";
import { GET } from "@/utils/http";

export default {
  name: "ManageThaibevProduct",
  components: { ManageProductListHorizon, DefaultDisplayStage },
  data() {
    return {
      // products: [
      //   {
      //     id: "p4",
      //     title: "ช้าง น้ำแร่ธรรมชาติ4",
      //     subTitle: "460 มล. แพ็ค 12",
      //     price: 120,
      //     oldPrice: 130,
      //     catId: 1,
      //   },
      //   {
      //     id: "p1",
      //     title: "ช้าง น้ำแร่ธรรมชาติ 1",
      //     subTitle: "460 มล. แพ็ค 12",
      //     price: 120,
      //     oldPrice: 130,
      //     catId: 1,
      //   },
      //   {
      //     id: "p2",
      //     title: "ช้าง น้ำแร่ธรรมชาติ 2",
      //     subTitle: "460 มล. แพ็ค 12",
      //     price: 120,
      //     oldPrice: 130,
      //     catId: 1,
      //   },
      //   {
      //     id: "p3",
      //     title: "ช้าง น้ำแร่ธรรมชาติ 3",
      //     subTitle: "460 มล. แพ็ค 12",
      //     price: 120,
      //     oldPrice: 130,
      //     catId: 2,
      //   },
      //   {
      //     id: "p5",
      //     title: "ช้าง น้ำแร่ธรรมชาติ 5",
      //     subTitle: "460 มล. แพ็ค 12",
      //     price: 120,
      //     oldPrice: 130,
      //     catId: 2,
      //   },
      //   {
      //     id: "p6",
      //     title: "ช้าง น้ำแร่ธรรมชาติ 5",
      //     subTitle: "460 มล. แพ็ค 12",
      //     price: 120,
      //     oldPrice: 130,
      //     catId: 2,
      //   },
      // ],
      // category: [
      //   { id: 1, name: "Chang Classic" },
      //   { id: 2, name: "Asha" },
      // ],
      products: [],
      categories: [],
      fetching: false,
      error: false,
    };
  },
  computed: {
    nodata() {
      return !this.fetching && this.categories.length <= 0 && !this.error;
    },
  },
  methods: {
    async getThaibevBands() {
      try {
        this.fetching = true;

        const { status, body } = await GET(
          `/api/shop/${this.$myShop.id}/brands?page=1&limit=20&query=&companyGroup=thaibev&forImport=false&all=true`
        );
        console.log("getThaibevBands status body", status, body);
        if (status !== 200) {
          throw "not ok";
        }
        this.categories = body.data;
        // this.processData()
      } catch (err) {
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
  },
  mounted() {
    console.log("mounted: ProductsThaibev");
    this.getThaibevBands();
  },
};
</script>