<template>
  <div>
    <div class="item" v-for="(item, index) of data" :key="index">
      <div class="img-container">
        <img v-if="item.shopPaymentMethod && 
                  item.shopPaymentMethod.paymentMethod && 
                  item.shopPaymentMethod.paymentMethod.slug == 'transfer'" 
                  :src="item.bank && item.bank.logo" />
        <img v-else :src="require('@/assets/img/promptpay-logo.png')" />
      </div>
      <div class="data">
        <div v-if="item.shopPaymentMethod && 
                  item.shopPaymentMethod.paymentMethod && 
                  item.shopPaymentMethod.paymentMethod.slug == 'transfer'">
          <b>{{ item.bank && item.bank.name }}</b>
        </div>
        <div v-else><b>พร้อมเพย์</b></div>

        <div v-if="item.shopPaymentMethod && 
                  item.shopPaymentMethod.paymentMethod && 
                  item.shopPaymentMethod.paymentMethod.slug == 'transfer'">
          สาขา <b>{{ item.branchName }}</b>
        </div>
        <div>ชื่อบัญชี <b>{{ item.accountName }}</b></div>
        <div>เลขที่บัญชี <b>{{ item.accountNo }}</b></div>
      </div>
      <div>
        <div class="button" @click="click(item)">แก้ไข</div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  props: {
    data: Array,
    editItemClick: Function,
  },
  data() {
    return {};
  },
  methods: {
    click(item) {
      this.editItemClick && this.editItemClick(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.item {
  margin-bottom: 10px;
  display: flex;
  padding: 12px 12px;
  border-radius: 10px;
  border: 1px solid #d8d8d8;
  min-height: 40px;
  user-select: none;
  font-size: 13px;
  .data > div:not(:last-child) {
    margin-bottom: 5px;
  }
  .img-container {
    width: 67px;
  }
  img {
    border-radius: 10px;
    overflow: hidden;
    margin-right: 8px;
    width: 56px;
    // background: orangered;
  }
  .data {
    flex: 1;
  }
  .button {
    padding: 8px 16px;
    border: 1px solid #eaeaea;
    border-radius: 10px;
  }
}
</style>