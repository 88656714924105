<template>
  <div class="menuBar">
    <div
      v-for="menu in menuList"
      :key="menu.key"
      @click="clickMenu(menu.route)"
      :class="{ active: selectedMenu == menu.route }"
    >
      {{ menu.name }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedMenu: "ProductsRecommend",
      menuList: [
        {
          name: "แนะนำ",
          key: "recommend",
          route: "ProductsRecommend",
        },
        {
          name: "สินค้าไทยเบฟ",
          key: "thaibev",
          route: "ProductsThaibev",
        },
        {
          name: "สินค้าทั้งหมด",
          key: "all_product",
          route: "ProductsAll",
        },
        {
          name: "หมวดหมู่",
          key: "category",
          route: "ProductsCategory",
        },
      ],
    };
  },
  watch: {
    $route() {
      this.selectedMenu = this.$route.name;
    },
  },
  methods: {
    clickMenu(val) {
      this.selectedMenu = val;
      this.$emit("selectMenu", val);
      if (this.$route.name != this.selectedMenu) {
        this.$router.push({ name: val });
      }
    },
  },
  mounted() {
    this.selectedMenu = this.$route.name;
  },
};
</script>
<style lang="scss" scoped>
// .menuBar {
//   user-select: none;
//   display: flex;
//   background: #ffffffc2;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 40px;
//   // padding: 0 5px;
//   box-shadow: 0 1px 6px 0px rgb(0 0 0 / 20%);
//   > div {
//     width: 100%;
//     text-align: center;
//     color: #55585b; //rgb(131, 131, 131);
//     font-size: clamp(12px, 3.5vw, 13px);
//     padding: 5px 2px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     border-radius: 8px;
//     margin: 0 4px;
//     height: 28px;
//     cursor: pointer;
//     // height: 30px;
//     &.active {
//       color: #ffffff; // rgb(112, 112, 112);
//       border-color: rgb(218, 218, 218);
//       background: var(--primary-color); //rgb(218, 218, 218);
//     }
//   }
// }

.menuBar {
  user-select: none;
  display: flex;
  background: #ffffff;
  justify-content: center;
  padding-top: 8px;
  // align-items: center;
  width: 100%;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
  > div {
    width: 100%;
    text-align: center;
    color: #55585b; //rgb(131, 131, 131);
    font-size: clamp(12px, 3.5vw, 13px);
    padding: 3px 2px 10px 2px;
    display: inline-block;
    border-radius: 8px;
    margin: 0 4px;
    cursor: pointer;
    // height: 30px;
    &.active {
      position: relative;
      // color: #ffffff; // rgb(112, 112, 112);
      // border-color: rgb(218, 218, 218);
      // background: var(--primary-color); //rgb(218, 218, 218);
    }
  }
}

.active:after {
  content: "";
  width: 80%;
  border-bottom: 2px solid var(--primary-color) !important;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  left: 50%;
}
</style>
