<template>
  <div class="navbar">
    <div class="innerNav">
      <div class="title">รายการสินค้า</div>
      <div class="addProduct" @click="openModal = true">
        <div class="plusIcon"></div>
        <!-- <img src="@/assets/img/plus-in-cgreen-circle.svg" alt="" /> -->
        <span>เพิ่มสินค้า</span>
      </div>
    </div>
    <AddProductModal :open.sync="openModal" />
  </div>
</template>
<script>
import AddProductModal from "./components/AddProductModal.vue";

export default {
  components: { AddProductModal },
  data() {
    return {
      openModal: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  z-index: 3;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 45px;
  width: 100%;
  > .innerNav {
    display: flex;
    color: #242424;
    justify-content: space-between;
    font-size: 14px;
    height: 100%;
    // line-height: 20px;
    padding: 0 14px;
    background: #fff;
    align-items: center;
    // margin: 0 auto;
  }
}

.title {
  font-size: 18px;
  color: #606060;
  font-weight: bold;
}

.addProduct {
  color: #313131;
  cursor: pointer;
  margin-top: 4px;
  background: #ececec;
  padding: 14px 4px;
  border-radius: 8px;
  height: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    padding-right: 3px;
  }

  .plusIcon {
    background-color: var(--primary-color);
    width: 17px;
    height: 17px;
    margin-right: 3px;
    -webkit-mask: url(~@/assets/img/plus-in-cgreen-circle.svg) no-repeat center /
      contain;
  }
}
</style>