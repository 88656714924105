<template>
  <div class="content">
    <Modal :open="open" :closeIcon="false" class="modal">
      <div class="modalBody">
        <div
          class="btn itemBtn"
          @click="$router.push({ name: 'ImportThaibev' })"
        >
          นำเข้าสินค้า Thaibev
        </div>
        <div class="btn itemBtn" @click="$router.push({ name: 'AddProduct' })">
          เพิ่มสินค้าใหม่
        </div>
        <div class="btn closeBtn" @click="$emit('update:open', false)">
          กลับ
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  props: { open: Boolean },
  components: { Modal },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.content {
  text-align: center;
  line-height: 22px;
}

.modalBody {
  max-width: 300px;
  width: 80vw;
}

.btn {
  background: rgb(255, 255, 255);
  margin: 15px 0;
}

.closeBtn {
  border: 1px solid rgb(187, 187, 187);
}

.itemBtn {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
</style>