<template>
  <div class="importCategory">
    <div class="nav">
      <div class="navInner">
        <!-- <img @click="$router.back()" src="@/assets/img/left-arrow.svg" alt="" /> -->
        <div class="mask-image" @click="$router.back()"></div>
        <span class="title">{{ catName }}</span>
      </div>
    </div>
    <ImportProductList
      :products="products"
      :fetching="fetching"
      :error="error"
      :endList="endList"
      :page="page"
      :pageSize="pageSize"
      @scrollEnd="getCategoryProduct"
      :nodata="nodata"
    />
  </div>
</template>

<script>
import ImportProductList from "./ImportProductList";
import { GET } from "@/utils/http";

export default {
  name: "ImportThaibevCategory",
  props: { catName: String, catId: String },
  components: { ImportProductList },
  data() {
    return {
      products: [],
      fetching: false,
      error: false,
      endList: false,
      page: 1,
      pageSize: 6,
    };
  },
  computed: {
    nodata() {
      return !this.fetching && this.products.length <= 0 && !this.error;
    },
  },
  methods: {
    async getCategoryProduct() {
      try {
        this.fetching = true;
        // const { status, body } = await GET(
        //   `/api/products?page=1&limit=100&query=&companyGroup=thaibev&brandIds=${this.catId}`
        // );
        const { status, body } = await GET(
          `/api/shops/${this.$myShop.id}/products?page=${this.page}&limit=${this.pageSize}&query=&brandIds=${this.catId}&forImport=true`
        );

        if (status !== 200) {
          throw "not ok";
        }

        this.products.push(...body.data);

        if (this.page >= body.lastPage) {
          this.endList = true;
        } else {
          this.page++;
        }
      } catch (err) {
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
  },
  mounted() {
    this.getCategoryProduct();
  },
};
</script>
<style lang="scss" scoped>
.importCategory {
  padding: 40px 0;
  background: #fff;
  height: 100%;
  position: relative;
}

.nav {
  position: fixed;
  top: 0;
  z-index: 2;
  // background: white;
  width: 100%;
  height: 45px;
  // box-shadow: 0 0 3px rgba(0, 0, 0, 0.137);

  .navInner {
    background: #ffffffc2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 6px;
    border-radius: 7px;
    > img {
      width: 25px;
    }
    .mask-image {
      width: 25px;
      height: 25px;
      mask-image: url("~@/assets/img/left-arrow.svg");
    }
  }

  .title {
    padding-left: 8px;
  }
}

.item {
  margin: 10px;
  display: flex;
  padding: 5px 10px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d8d8d8;
  min-height: 60px;
}
</style>