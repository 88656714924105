<template>
  <div class="h100" style="padding: 20px 0">
    <DefaultDisplayStage :error="error" :nodata="nodata" />
    <div class="cardWrap">
      <div
        class="card"
        v-for="(item, index) in products"
        :key="index"
        @click.stop="showProduct(item)"
      >
        <div :class="[productCompanyGroupName(item), 'imgWrap']">
          <img
            v-if="item.productPhotos"
            :src="
              item.productPhotos.length > 0
                ? item.productPhotos[0].url
                : $placeholderImg
            "
          />
          <img v-else :src="$placeholderImg" />
        </div>
        <div class="detailWrap">
          <div>{{ item.name }}</div>
          <!-- <div>{{ item.description }}</div> -->
          <div class="priceWrap">
            <span class="price">
              <span v-if="calStep(item)"
                >{{ calStep(item).min.toLocaleString() }} ~
                {{ calStep(item).max.toLocaleString() }}</span
              >
              <span v-else>{{ item.salePrice.toLocaleString() }}</span>
            </span>
            <span class="unitPrice">บาท</span>
            <span v-if="item.price !== item.salePrice" class="fromPrice"
              >{{ item.price }} <span class="">บาท</span></span
            >
          </div>
        </div>
        <div class="actionContain">
          <div class="actionWrap">
            <div v-if="item.adding" class="addingBtn btn">
              <img src="@/assets/img/loader.svg" width="15" alt="" />
              กำลังเพิ่ม...
            </div>
            <div v-else>
              <div
                v-if="!item.child"
                class="addBtn btn"
                @click.stop="addProduct(item.id)"
              >
                + เพิ่ม
              </div>
              <div v-if="item.child" class="addedBtn btn">เพิ่มแล้ว</div>
            </div>
          </div>
        </div>
      </div>
      <ImportProductModal @addProduct="addProduct" />
    </div>
    <scroll-loader
      :loader-method="() => $emit('scrollEnd')"
      :loader-disable="fetching || endList || error"
      :loader-size="0"
      class="reset"
    >
    </scroll-loader>
    <ProductListLoader v-if="fetching" />
    <div v-if="products.length > 0" style="height: 100px"></div>
  </div>
</template>

<script>
import ImportProductModal from "./ImportProductModal";
import { call } from "vuex-pathify";
import { POST } from "@/utils/http";
import ProductListLoader from "@/components/loader/ProductListLoader";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";
import { calStep } from "@/utils/product";

export default {
  components: { ImportProductModal, ProductListLoader, DefaultDisplayStage },
  props: {
    products: Array,
    fetching: Boolean,
    endList: Boolean,
    error: Boolean,
    nodata: Boolean,
  },
  methods: {
    calStep: calStep,
    ...call("showProduct/*"),
    async addProduct(pid) {
      if (!(await window.$alert("ต้องการเพิ่มหรือไม่?", "confirm"))) {
        return;
      }

      const index = this.products.findIndex((e) => e.id == pid);
      try {
        this.$set(this.products[index], "adding", true);

        const { status } = await POST(
          `/api/products/${pid}/clone/${this.$myShop.id}`
        );

        if (status !== 200) {
          throw "not ok";
        }

        this.$set(this.products[index], "child", [{}]);
      } catch (err) {
        window.$alert("ไม่สามารถดำเนินการได้ในขณะนี้");
      } finally {
        this.$set(this.products[index], "adding", false);
      }
    },
  },
  mounted() {
    console.log("product list");
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/productCardList.scss";

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  font-size: 12px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  margin: 0 auto;
}

.addBtn {
  background: var(--primary-color);
  color: #fff;
}
.addedBtn {
  background: #a0a0a0;
  color: #fff;
}
.addingBtn {
  background: #afafaf;
  color: #fff;
  > img {
    padding-right: 5px;
  }
}
</style>