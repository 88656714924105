<template>
  <div class="settingMain">
    <NavbarBack title="ตั้งค่าช่องทางรับชำระเงิน"/>
    
    <div class="container">
      <DefaultDisplayStage :fetching="fetching" :error="error" :nodata="nodata" />
      <ListToggleSwitch :data="data" @click="onToggleClick"/>
    </div>
  </div>
</template>

<script>
import { GET,POST} from "@/utils/http";

import NavbarBack from "@/components/NavbarBack";
import ListToggleSwitch from "@/components/ListToggleSwitch";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";

export default {
  components: { NavbarBack ,ListToggleSwitch,DefaultDisplayStage},
  data() {
    return {
      fetching:false,
      error:false,
      enabled_list:[],
      payment_methods_list:[],
    };
  },
  computed: {
    nodata() {
      return !this.fetching && this.data.length <= 0 && !this.error;
    },
    data(){
      return this.payment_methods_list.map(item=>{
        const shop_payment_enable = this.enabled_list.find(obj=>obj.paymentMethod && obj.paymentMethod.id === item.id);
      
        return {
          ...item,
          checked:shop_payment_enable && shop_payment_enable.is_enable || false
        }
      })
    }
  },
  methods: {
    async getList() {
      try {
        this.fetching = true;

        const { status, body } = await GET(
          `/api/payment_methods`
        );
        if (status !== 200) {
          throw "not ok";
        }
        this.payment_methods_list=body;

        const {status:s_status,body:s_body} = await GET(
          `/api/shops/${this.$myShop.id}/payment_methods`
        );

        if (s_status !== 200) {
          throw "not ok";
        }
        this.enabled_list=s_body;

      } catch (err) {
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },

    async onToggleClick({item}){
      console.log("click",item)
      const { status } = await POST(`/api/shops/${this.$myShop.id}/payment_methods/${item.id}`);
      if (status !== 200) {
        throw "not ok";
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.settingMain {
  padding-top: 40px;
  background: #fff;
  height: 100%;
  
}
.container{
  padding:  0 16px;
}
.title {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-button{
  color: var(--primary-color);
  cursor: pointer;
  margin-top: 4px;
  background: #ececec;
  padding: 14px 8px;
  border-radius: 8px;
  height: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container{
    display: flex;
    padding: 0 16px;
    padding-bottom: 8px;
    justify-content: flex-end;
}
.button{
    cursor: pointer;
    background: #ececec;
    display: flex;
    justify-content: center;
    align-items: center;  
    padding: 16px 16px;
    border-radius: 8px;
    height: 20px;
    font-size: 13px;
}

</style>