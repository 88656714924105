<template>
  <div class="settingMain">
    <div class="title">ตั้งค่า</div>
    <Category
      class="wrapCategory"
      :data="categoryData"
      v-on:click="categoryClick"
    />
  </div>
</template>

<script>
import Category from "@/views/components/Category";

export default {
  components: { Category },
  data() {
    return {
      categoryData: [
        {
          name: "การรับชำระเงิน",
          path: "receive-payment",
          type: ["seller"],
        },
        {
          name: "ช่องทางรับชำระเงิน",
          path: "payment-method",
          type: ["seller"],
        },
        {
          name: "บัญชีธนาคาร",
          path: "bank",
          type: ["seller"],
        },
        {
          name: "ตั้งค่าที่อยู่จัดส่ง",
          path: "address",
          type: ["buyer"],
        },
        {
          name: "จัดการความสัมพันธ์",
          path: "relation-manage",
          type: ["buyer"],
        },
        {
          name: "ตั้งค่าผู้ใช้งาน",
          path: "user-setting",
          type: ["seller"],
        },
        {
          name: "ตั้งค่าโปรโมชั่น",
          path: "promotion",
          type: ["seller"],
        },
        {
          name: "ข้อมูลการยินยอม",
          path: "consents",
          type: ["seller", "buyer"],
        },
      ],
    };
  },
  methods: {
    categoryClick(item) {
      this.$router.push({
        path: `/settings/${item.path}`,
      });
    },
  },
  created() {
    this.categoryData = this.categoryData.filter((e) =>
      e.type?.includes(this.$shopType)
    );
  },
};
</script>
<style lang="scss" scoped>
.settingMain {
  // padding-top: 60px;
  background: #fff;
  height: 100%;
}

.title {
  font-size: 18px;
  padding: 14px;
  color: #606060;
  font-weight: bold;
}

.wrapCategory {
  padding-top: 10px;
}
</style>
