<template>
  <ProductDetail
    :product="product"
    :fetching="fetching"
    :error="error"
    :nodata="nodata"
  />
</template>

<script>
import ProductDetail from "./ProductDetail";
import { GET } from "@/utils/http";

export default {
  props: { productId: String },
  components: { ProductDetail },
  data() {
    return {
      fetching: false,
      error: false,
      nodata: false,
      product: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log("enter", to, from);
    if (!from.name) {
      window.$forceBackTo = { path: "/products" };
    }
    next();
  },
  methods: {
    async getProduct() {
      try {
        this.fetching = true;
        const { status, body } = await GET(`/api/products/${this.productId}`);

        if (status !== 200) {
          throw "not ok";
        }

        this.product = body;
      } catch (err) {
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
  },
  mounted() {
    this.getProduct();
    console.log(this.productId);
  },
};
</script>