<template>
  <div class="content">
    <Modal :open="open" :closeIcon="false" class="modal">
      <div class="modalBody">
        <div>
          {{ dialogData.text }}
        </div>
        <div v-if="dialogData.hideButton" class="wrapCheckBox">
          <Checkbox v-model="checkBoxInput" :id="'checkBoxInput'" />
          <label for="checkBoxInput">ไม่ต้องแสดงอีก</label>
        </div>
        <div>
          <div class="btn" @click="closeDialog()">ปิดหน้าต่าง</div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import Checkbox from "@/components/Checkbox";
import { GET, POST } from "@/utils/http";

export default {
  components: { Modal, Checkbox },
  data() {
    return {
      open: false,
      checkBoxInput: false,
      dialogData: {},
    };
  },
  methods: {
    async getDialogs() {
      try {
        if (window.$dialogAlcoholShown) {
          return;
        }
        window.$dialogAlcoholShown = true;

        if (!process.env.VUE_APP_ALCOHOL_DIALOG_NAME) {
          return;
        }

        window.loading(true);
        const { status, body } = await GET(
          `/api/dialogs/active?query=${process.env.VUE_APP_ALCOHOL_DIALOG_NAME}`
        );

        if (status !== 200) {
          throw `get dialogs ${process.env.VUE_APP_ALCOHOL_DIALOG_NAME}: not ok is status ${status}`;
        }
        window.loading(false);
        if (!body[0].active) {
          return;
        }
        this.dialogData = body[0];
        this.open = true;
      } catch (err) {
        window.loading(false);
      }
    },
    async closeDialog() {
      try {
        if (!this.dialogData.hideButton || !this.checkBoxInput) {
          this.open = false;
          return;
        }

        window.loading(true);
        const { status } = await POST(
          `/api/dialogs/${this.dialogData.id}/hide`
        );

        if (status !== 200) {
          throw `hide dialogs: not ok is status ${status}`;
        }
        window.loading(false);
        this.open = false;
      } catch (err) {
        this.open = false;
        window.loading(false);
      }
    },
  },
  mounted() {
    this.getDialogs();
  },
};
</script>

<style lang="scss" scoped>
.content {
  text-align: center;
  line-height: 22px;
}

.modalBody {
  max-width: 300px;
  width: 80vw;
}

.wrapCheckBox {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  label {
    margin-left: 10px;
  }
}

.btn {
  background: rgb(214, 214, 214);
  margin: 20px 0 0 0;
}
</style>