<template>
  <div class="navbar">
    <div class="innerNav">
      <div>{{ shopName }}</div>
      <div class="wrapImg">
        <div @click="$router.push('/search/products')" class="searchIcon">
          <img src="@/assets/img/search.svg" />
        </div>
        <div v-if="$isBuyer" @click="$router.push('/cart')">
          <img src="@/assets/img/shopping-cart.svg" />
          <div class="countCart" v-if="cacheCount > 0">
            <div>{{ cacheCount }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Search :show="showSearch" @close="showSearch = false" /> -->
    <!-- <SearchProduct
      :show="showSearch"
      @close="showSearch = false"
      @result="gotoProduct"
    /> -->
  </div>
</template>
<script>
import { get, call } from "vuex-pathify";
// import Search from "./Search";
// import SearchProduct from "@/views/order/components/SearchProduct";

export default {
  // components: { SearchProduct },
  computed: {
    ...get("cart/*"),
    shopName() {
      if (this.$isBuyer) {
        return this.$sellerShop?.name;
      }
      if (this.$isSeller) {
        return this.$myShop?.name;
      }
      return null;
    },
  },
  methods: {
    ...call("cart/*"),
  },
  data() {
    return {
      // showSearch: false,
    };
  },
  created() {
    if (this.$isBuyer) {
      this.fetchListCart();
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.navbar {
  z-index: 3;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 35px;
  width: 100%;
  > .innerNav {
    display: flex;
    color: #fff;
    justify-content: space-between;
    font-size: 14px;
    height: 100%;
    // line-height: 20px;
    padding: 0 14px;
    background: var(--primary-color);
    align-items: center;
    // margin: 0 auto;
  }
  &::after {
    content: "";
    position: absolute;
    background: linear-gradient(
      32deg,
      rgba(230, 204, 110, 1) 0%,
      rgba(161, 143, 41, 1) 25%,
      rgba(237, 222, 158, 1) 50%,
      rgba(161, 143, 41, 1) 75%,
      rgba(237, 222, 158, 1) 100%
    );
    width: 100%;
    height: 4px;
  }
}

.wrapImg {
  display: flex;
  align-items: center;
  margin-right: -10px;
  img {
    width: 20px;
    margin: 0 10px;
  }
}

.countCart {
  position: absolute;
  right: 6px;
  top: 3px;
  display: flex;
  background: crimson;
  border-radius: 100%;
  padding: 0 4px;
  height: 14px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.searchIcon {
  margin: 0 10px;
  > img {
    width: 18px;
  }
}
</style>