<template>
  <div class="h100">
    <Filters @metaJoin="updatefilters" :list="filtersList" />
    <ManageProductList
      :products="products"
      :fetching="fetching"
      :error="error"
      :endList="endList"
      :page="page"
      :nodata="nodata"
      :pageSize="pageSize"
      @scrollEnd="getProducts"
    />
  </div>
</template>

<script>
import ManageProductList from "./ManageProductList";
import Filters from "@/views/components/Filters";
import { GET } from "@/utils/http";

// Filter Product
//  - query [string]
//  - brandIds [string => 1,2,3]
//  - companyIds [string => 1,2,3]
//  - categoryIds [string => 1,2,3]
//  - productTypeIds [string => 1,2,3]
//  - companyGroup [string => thaibev]
//  - addons [*bool]
//  - refStandardPrice [*bool]
//  - productStatus [string => วางขาย,ไม่แสดง,สินค้าหมด,ยกเลิก]
//  - status [string => รออนุมัติ,อนุมัติ,รอแก้ไข,ระงับ]

export default {
  name: "ManageProductAll",
  components: { ManageProductList, Filters },
  data() {
    return {
      products: [],
      fetching: false,
      error: false,
      endList: false,
      page: 1,
      pageSize: 10,
      filtersList: [
        {
          name: "วางขาย",
          meta: { productStatus: "วางขาย" },
        },
        {
          name: "ไม่แสดง",
          meta: { productStatus: "ไม่แสดง" },
        },
        {
          name: "สินค้าหมด",
          meta: { productStatus: "สินค้าหมด" },
        },
        {
          name: "ยกเลิก",
          meta: { productStatus: "ยกเลิก" },
        },
        {
          name: "รออนุมัติ",
          meta: { status: "รออนุมัติ" },
        },
        {
          name: "อนุมัติ",
          meta: { status: "อนุมัติ" },
        },
        {
          name: "รอแก้ไข",
          meta: { status: "รอแก้ไข" },
        },
        {
          name: "ระงับ",
          meta: { status: "ระงับ" },
        },
      ],
      outputFilters: {
        productStatus: [],
        status: [],
      },
    };
  },
  computed: {
    nodata() {
      return (
        !this.fetching &&
        this.products.length <= 0 &&
        !this.error &&
        this.endList
      );
    },
  },
  watch: {
    outputFilters: {
      deep: true,
      handler() {
        this.resetProducts();
      },
    },
  },
  methods: {
    resetProducts() {
      this.page = 1;
      this.endList = false;
      this.products = [];
      this.getProducts();
    },
    updatefilters(metaJoin) {
      this.outputFilters = metaJoin;
    },
    async getProducts() {
      try {
        this.fetching = true;

        const filtersQuery = new URLSearchParams(this.outputFilters).toString();

        const { status, body } = await GET(
          `/api/shops/${this.$myShop.id}/products?page=${this.page}&limit=${this.pageSize}&query=&forImport=false&all=true&${filtersQuery}`
        );

        if (status !== 200) {
          throw "not ok";
        }

        this.products.push(...body.data);

        if (this.page >= body.lastPage) {
          this.endList = true;
        } else {
          this.page++;
        }
      } catch (err) {
        console.error(err);
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
  },
  mounted() {
    // this.getProducts();
  },
};
</script>