<template>
  <div class="settingMain">
    <NavbarBack :title="'เปิดใช้เครดิต'" />
    <div class="content">
      <div style="height: 50px"></div>
      <div class="mainAction">
        <div @click="enableAll()" class="enableAll btn">เปิดทั้งหมด</div>
        <div @click="disableAll()" class="disableAll btn">ปิดทั้งหมด</div>
      </div>
      <div class="title">รายชื่อร้านค้า</div>
      <ListToggleSwitch :data="listToggle" v-on:click="toggleClick" />
      <div style="height: 50px"></div>
    </div>
  </div>
</template>

<script>
import ListToggleSwitch from "@/components/ListToggleSwitch";
import NavbarBack from "@/components/NavbarBack";
import GetBuyers from "@/hooks/getBuyers";
import { PUT, PATCH } from "@/utils/http";

export default {
  components: { ListToggleSwitch, NavbarBack },
  data() {
    return {
      buyers: [],
    };
  },
  computed: {
    listToggle() {
      return this.buyers.map((shop) => {
        return {
          ...shop,
          name: shop.buyer.name,
          checked: shop.creditAvailable,
        };
      });
    },
  },
  methods: {
    async toggleClick(result) {
      try {
        window.loading(true);

        const reqBody = {
          buyerId: result.item.buyerId,
          creditAvailable: result.value,
        };

        const { status } = await PUT(
          `/api/shops/${this.$myShop.id}/partners/credit`,
          reqBody
        );

        if (status !== 200) {
          throw "partners credit enable : not ok is status " + status;
        }

        window.loading(false);
      } catch (err) {
        window.loading(false);
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
        this.$router.back();
      }
    },
    async getBuyers() {
      window.loading(true);

      const { body, error } = await GetBuyers(1, 1000);
      window.loading(false);

      if (error) {
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
        this.$router.back();
        return;
      }

      this.buyers = body.data;
    },
    async updateAll(enable) {
      try {
        window.loading(true);

        const reqBody = {
          available: enable,
        };

        const { status } = await PATCH(
          `/api/shops/${this.$myShop.id}/partners/credit/available`,
          reqBody
        );

        if (status !== 200) {
          throw "partners credit enable all: not ok is status " + status;
        }

        this.getBuyers();
        window.loading(false);
      } catch (err) {
        window.loading(false);
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
        this.$router.back();
      }
    },
    async enableAll() {
      if (!(await window.$alert("ต้องการเปิดทั้งหมด?", "confirm"))) {
        return;
      }
      this.updateAll(true);
    },
    async disableAll() {
      if (!(await window.$alert("ต้องการปิดทั้งหมด?", "confirm"))) {
        return;
      }
      this.updateAll(false);
    },
  },
  mounted() {
    this.getBuyers();
  },
};
</script>
<style lang="scss" scoped>
.settingMain {
  // padding-top: 60px;
  background: #fff;
  height: 100%;
}

.title {
  // text-align: center;
  font-size: 15px;
  padding: 10px 0 5px 15px;
  font-weight: bold;
}

.content {
  overflow: auto;
}

.mainAction {
  display: flex;
  justify-content: center;
  margin: 15px;
  .enableAll {
    background: var(--primary-color);
  }
  .disableAll {
    background: rgb(192, 77, 77);
  }
  .btn {
    color: rgb(255, 255, 255);
    max-width: 180px;
    padding: 10px;
    margin: 10px;
  }
}
</style>