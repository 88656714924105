<template>
  <div class="settingMain">
    <NavbarBack title="ตั้งค่าบัญชีธนาคาร" />
    <div class="button-container">
      <div class="button" @click="onAddClick">เพิ่มบัญชีธนาคาร</div>
    </div>
    <div class="container">
      <DefaultDisplayStage
        :fetching="fetching"
        :error="error"
        :nodata="nodata"
      />
      <BankList :data="data" :editItemClick="onEditClick" />
    </div>
  </div>
</template>

<script>
import { GET } from "@/utils/http";

import NavbarBack from "@/components/NavbarBack";
import BankList from "@/views/components/BankList";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";

export default {
  components: { NavbarBack, BankList, DefaultDisplayStage },
  data() {
    return {
      fetching: false,
      error: false,
      data: [],
    };
  },
  computed: {
    nodata() {
      return !this.fetching && this.data.length <= 0 && !this.error;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getList();
    });
  },
  methods: {
    async getList() {
      try {
        this.fetching = true;
        const { status, body } = await GET(
          `/api/shops/${this.$myShop.id}/payment_methods/details`
        );

        if (status !== 200) {
          throw "not ok";
        }

        this.data = body;
      } catch (err) {
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
    onAddClick() {
      this.$router.push({
        name: `BankDetailSetting`,
        params: {
          id: null,
        },
      });
    },
    onEditClick(item) {
      const {
        id,
        bank,
        branchName,
        accountName,
        accountNo,
        shopPaymentMethod,
      } = item;
      this.$router.push({
        name: `BankDetailSetting`,
        params: {
          id,
          original_data: {
            bankId: bank?.id,
            branchName,
            accountName,
            accountNo,
            slug: shopPaymentMethod?.paymentMethod?.slug ?? "transfer",
          },
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.settingMain {
  padding-top: 40px;
  background: #fff;
  height: 100%;
}
.container {
  padding: 0 16px;
}
.title {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-button {
  color: var(--primary-color);
  cursor: pointer;
  margin-top: 4px;
  background: #ececec;
  padding: 14px 8px;
  border-radius: 8px;
  height: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  padding: 0 16px;
  padding-bottom: 8px;
  justify-content: flex-end;
}
.button {
  cursor: pointer;
  background: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 16px;
  border-radius: 8px;
  height: 20px;
  font-size: 13px;
}
</style>