<template>
  <div class="productContent">
    <div class="nav">
      <div class="navInner">
        <!-- <img @click.stop="back()" src="@/assets/img/left-arrow.svg" alt="" /> -->
        <div @click.stop="back()" class="backBtn mask-image"></div>
        <!-- <span class="title">นำเข้าสินค้า Thaibev</span> -->
      </div>
    </div>
    <ProductDetailLoader :error="error" :nodata="nodata" :fetching="fetching" />
    <div v-if="product.id" class="productDetail">
      <div class="imgWrap">
        <div v-if="product.addons" class="strip">
          <span>โปรโมชั่น</span>
        </div>
        <img
          v-if="product.productPhotos && product.productPhotos.length > 0"
          :src="product.productPhotos[0].url"
        />
        <img v-else :src="$placeholderImg" />
        <div v-if="product.alcohol" class="overlayAlcohol">
          ภาพใช้เพื่อ
          การตรวจสอบ/ยืนยันสินค้าที่สั่งซื้อระหว่างผู้ประกอบการกับผู้ประกอบการเท่านั้น
        </div>
        <OverlayOutofStock :product="product" />
      </div>
      <div class="detailWrap">
        <div class="productTitle">{{ product.name }}</div>
        <div class="priceWrap">
          <span class="price">
            <span v-if="calStep(product)"
              >{{ calStep(product).min.toLocaleString() }} ~
              {{ calStep(product).max.toLocaleString() }}</span
            >
            <span v-else>{{ product.salePrice.toLocaleString() }}</span>
          </span>
          <span class="unitPrice">บาท</span>
          <span v-if="product.price !== product.salePrice" class="fromPrice"
            >{{ product.price }} <span class="unitPrice">บาท</span></span
          >
          <span class="unit"> / {{ product.unit }}</span>
        </div>
        <div v-if="product.addons" class="productSubTitle">
          <div class="desTitle"><b>รายละเอียดโปรโมชั่น</b></div>
          {{ product.addonsDetail }}
        </div>
        <div class="productSubTitle">
          <div class="desTitle"><b>รายละเอียดสินค้า</b></div>
          {{ product.description }}
        </div>
        <StepPriceDetail :product="product" />
        <div v-if="$isBuyer" style="height: 180px"></div>
      </div>
      <div class="actionWrap" v-if="$isBuyer && !outofStock">
        <div class="wrapQuntity">
          <div>จำนวน</div>
          <div class="wrapQtyInput">
            <img
              @click="amount = parseInt(amount) - 1"
              src="@/assets/img/minus.svg"
              alt=""
            />
            <input type="number" min="0" pattern="\d*" v-model.lazy="amount" />
            <img
              @click="amount = parseInt(amount) + 1"
              src="@/assets/img/add.svg"
              alt=""
            />
          </div>
          <div>{{ product.unit }}</div>
        </div>
        <div class="priceUnitWrap">
          <div>ราคาต่อหน่วย</div>
          <div>
            <span class="price">
              {{ calUnitPrice(product, amount).toLocaleString() }}
            </span>
            <span>บาท</span>
          </div>
        </div>
        <div class="priceSummaryWrap">
          <div><b>ราคารวม</b></div>
          <div>
            <span class="price">
              {{ (amount * calUnitPrice(product, amount)).toLocaleString() }}
            </span>
            <span>บาท</span>
          </div>
        </div>
        <div
          v-if="$isBuyer"
          class="buyBtn btn"
          @click="clickAddToCart({ product: product, amount: amount || 1 })"
          data-tag="buyBtn"
        >
          เพิ่มสินค้า
        </div>
      </div>
    </div>
    <AlertAddCart ref="alertAddCart" />
  </div>
</template>

<script>
import ProductDetailLoader from "@/components/loader/ProductDetailLoader";
import { call } from "vuex-pathify";
import isEmpty from "lodash/isEmpty";
import AlertAddCart from "@/components/AlertAddCart";
import StepPriceDetail from "@/components/StepPriceDetail";
import { calStep, calUnitPrice } from "@/utils/product";
import OverlayOutofStock from "./components/OverlayOutofStock";

export default {
  props: {
    product: {
      type: Object,
      default: () => ({
        id: null,
      }),
    },
    error: Boolean,
    nodata: Boolean,
    fetching: Boolean,
  },
  components: {
    ProductDetailLoader,
    AlertAddCart,
    StepPriceDetail,
    OverlayOutofStock,
  },
  data() {
    return {
      amount: 1,
    };
  },
  computed: {
    outofStock() {
      return this.product.product_status === "สินค้าหมด";
    },
  },
  watch: {
    amount(val) {
      if (val < 1) {
        this.amount = 1;
      }
    },
  },
  methods: {
    calStep: calStep,
    calUnitPrice: calUnitPrice,
    back() {
      !isEmpty(window.$forceBackTo)
        ? this.$router.push(window.$forceBackTo)
        : history.back();
    },
    ...call("cart/*"),
    clickAddToCart(data) {
      this.addCart(data);
      this.$refs.alertAddCart.tick();
    },
    // addToCart(data) {
    // var btnOffsetTop = $btn.offset().top;
    // var btnOffsetRight = window.innerWidth - $btn.offset().left;
    // $li
    //   .find("img")
    //   .clone()
    //   .css({ top: 10, right: 10 })
    //   .addClass("zoom")
    //   .appendTo($li);
    // const img = document.querySelector(
    //   `#pd-${data.product.id} .imgWrap > img`
    // );
    // const clone = img.cloneNode();
    // clone.id = `pd-${data.product.id}-clone`;
    // // clone.style.top = img.offsetTop + "px";
    // clone.style.color = "#00000";

    // // clone.style.right = window.innerWidth - img.offsetLeft + "px";
    // clone.classList.add("zoom");
    // console.log(clone);
    // document
    //   .querySelector(`#pd-${data.product.id} .imgWrap`)
    //   .appendChild(clone);

    // setTimeout(function () {
    // document.querySelector(`#pd-${data.product.id}-clone`).remove();
    // }, 1000);

    // this.addCart(data);
    // },
  },
  mounted() {
    console.log(this.product);
  },
};
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 45px;
}

.navInner {
  background: #ffffffc2;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 6px;
  border-radius: 7px;
}

.navInner > img {
  width: 25px;
}

.title {
  padding-left: 8px;
}

img {
  max-width: 100%;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.imgWrap {
  position: relative;

  img,
  .img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 500px;
    aspect-ratio: 1/1;
  }

  .strip {
    width: 100px;
    height: 100px;
    position: absolute;
    /*background: green;*/
    top: -24px;
    left: -52px;
    transform: rotate(-45deg);
    span {
      position: absolute;
      bottom: 0;
      display: block;
      font-size: 18px;
      color: #fff;
      background: #f43131;
      width: 141px;
      height: 32px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.productContent {
  background: #ffffff;
  height: 100%;
}

.productTitle {
  font-size: clamp(18px, 5vw, 24px);
  font-weight: bold;
  color: dimgrey;
}

.productSubTitle {
  margin: 20px 0;
  font-size: 16px;
  .desTitle {
    margin: 10px 0;
    color: #5c5c5c;
    font-size: 15px;
  }
}

.actionWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  z-index: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  background: #fff;
}

.wrapQtyInput {
  margin: 0 18px;
}

.buyBtn {
  background: var(--primary-color);
  color: #fff;
  margin: 0 auto;
}

.addedBtn {
  background: #a0a0a0;
  color: #fff;
}

.addingBtn {
  background: #afafaf;
  color: #fff;
  > img {
    padding-right: 5px;
  }
}

.priceWrap {
  margin-top: 15px;
  .price {
    font-size: 20px;
    padding-right: 3px;
    color: var(--primary-color);
  }
}

.detailWrap {
  font-size: 12px;
  padding: 4px 15px 4px 15px;
  z-index: 1;
  position: relative;
  line-height: 17px;
}

.fromPrice {
  font-size: 16px;
  margin-left: 6px;
  color: #989898;
  text-decoration: line-through;
}

.productDetail {
  overflow: scroll;
  height: 100%;
  // height: calc(100vh - 60px);
  // padding-bottom: 20px;
}

.wrapQuntity {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 0;
  padding: 4px;
  flex-wrap: wrap;
  // border-bottom: 1px solid rgb(231, 231, 231);
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  img {
    width: 20px;
  }
  input {
    border-radius: 8px;
    border: 1px solid rgb(192, 192, 192);
    padding: 5px;
    width: 100px;
    text-align: center;
    margin: 0 10px;
  }
}

.priceUnitWrap,
.priceSummaryWrap {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  margin: 8px 0;
  font-size: 16px;
  .price {
    color: var(--primary-color);
  }
}

.priceUnitWrap {
  font-size: 14px;
  .price {
    font-size: 16px;
  }
}
.priceSummaryWrap {
  .price {
    font-size: 19px;
  }
}

.overlayAlcohol {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  padding: 6px 15px;
  backdrop-filter: blur(2px);
  text-align: center;
  align-items: center;
  line-height: 20px;
  color: #d84040;
  font-size: 13px;
}

.backBtn {
  &.mask-image {
    width: 28px;
    height: 28px;
    mask-image: url("~@/assets/img/left-arrow.svg");
  }
}
</style>

<style lang="scss">
// .zoom {
//   position: fixed !important;
//   width: 100%;
//   opacity: 0;
//   z-index: 100;
//   animation: zoom 1s linear forwards;
// }

// @keyframes zoom {
//   from {
//     opacity: 1;
//   }
//   to {
//     opacity: 0;
//     top: 10px;
//     right: 10px;
//     width: 40px;
//     height: 40px;
//   }
// }
</style>