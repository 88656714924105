var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"settingMain"},[_c('NavbarBack',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"container"},[_c('label',[_vm._v("ชื่อ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],attrs:{"type":"text","placeholder":"ชื่อที่อยู่จัดส่ง"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),_c('label',[_vm._v("ผู้ติดต่อ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact_person),expression:"contact_person"}],attrs:{"type":"text","placeholder":"ชื่อ-นามสกุล"},domProps:{"value":(_vm.contact_person)},on:{"input":function($event){if($event.target.composing)return;_vm.contact_person=$event.target.value}}}),_c('label',[_vm._v("เบอโทรศัพท์")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tel),expression:"tel"}],attrs:{"type":"text","placeholder":"000-000-0000"},domProps:{"value":(_vm.tel)},on:{"input":function($event){if($event.target.composing)return;_vm.tel=$event.target.value}}}),_c('label',[_vm._v("จังหวัด")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.provinceId),expression:"provinceId"}],attrs:{"disabled":_vm.province_list.length == 0},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.provinceId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},(e) => {
          this.provinceId = e.target.value;
          this.districtId = null;
          this.subDistrictId = null;
          this.postcode = null;
          this.fetchDistrict();
          this.$forceUpdate();
        }]}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("เลือกจังหวัด")]),_vm._l((_vm.province_list),function(province){return _c('option',{key:province.id,domProps:{"value":province.id}},[_vm._v(" "+_vm._s(province.name)+" ")])})],2),_c('label',[_vm._v("อำเภอ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.districtId),expression:"districtId"}],attrs:{"disabled":_vm.district_disable},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.districtId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},(e) => {
          this.districtId = e.target.value;
          this.subDistrictId = null;
          this.postcode = null;
          this.fetchSubDistrict();
          this.$forceUpdate();
        }]}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("เลือกอำเภอ")]),_vm._l((_vm.district_list),function(district){return _c('option',{key:district.id,domProps:{"value":district.id}},[_vm._v(" "+_vm._s(district.name)+" ")])})],2),_c('label',[_vm._v("ตำบล")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.subDistrictId),expression:"subDistrictId"}],attrs:{"disabled":_vm.sub_district_disable},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.subDistrictId=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},(e) => {
          this.subDistrictId = e.target.value;
          this.postcode = null;

          this.fetchPostCode();
          this.$forceUpdate();
        }]}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("เลือกตำบล")]),_vm._l((_vm.sub_district_list),function(sub_district){return _c('option',{key:sub_district.id,domProps:{"value":sub_district.id}},[_vm._v(" "+_vm._s(sub_district.name)+" ")])})],2),_c('label',[_vm._v("รหัสไปรษณีย์")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.postcode),expression:"postcode"}],attrs:{"disabled":_vm.postcode_disable},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.postcode=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},(e) => {
          this.postcode = e.target.value;
        }]}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("รหัสไปรษณีย์")]),_vm._l((_vm.postcode_list),function(postcode){return _c('option',{key:postcode.postcode,domProps:{"value":postcode.postcode}},[_vm._v(" "+_vm._s(postcode.postcode)+" ")])})],2),_c('label',[_vm._v("รายละเอียดที่อยู่")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.detail),expression:"detail"}],attrs:{"rows":"3","placeholder":"ข้อความ"},domProps:{"value":(_vm.detail)},on:{"input":function($event){if($event.target.composing)return;_vm.detail=$event.target.value}}})]),_c('div',{staticClass:"bottom"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_edit),expression:"is_edit"}],staticClass:"button outline",on:{"click":_vm.onDelete}},[_vm._v(" ลบข้อมูล ")]),_c('div',{staticClass:"button",on:{"click":_vm.onSave}},[_vm._v("บันทึกข้อมูล")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }