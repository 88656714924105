<template>
  <transition name="slidefade">
    <div v-if="showModal && product.id" class="modal">
      <ImportProductDetail :product="product" @addProduct="addProduct" />
    </div>
  </transition>
</template>

<script>
import { sync, call } from "vuex-pathify";
import ImportProductDetail from "./ImportProductDetail";

export default {
  components: { ImportProductDetail },
  data() {
    return {
      // currentProductId: "",
    };
  },
  watch: {
    // product: {
    //   immediate: true,
    //   deep: true,
    //   handler() {
    //     if (this.product?.id) {
    //       console.log("handler");
    //       // history.pushState("", document.title, window.location.pathname);
    //       history.pushState(
    //         {},
    //         null,
    //         decodeURI(this.$route.fullPath) + "#product-" + this.product.id
    //       );
    //       document.body.classList.add("product-open");
    //     } else {
    //       console.log("on remove");
    //       document.body.classList.remove("product-open");
    //     }
    //   },
    // },
    showModal(val) {
      if (val) {
        history.pushState(
          {},
          null,
          decodeURI(this.$route.fullPath) + "#product-" + this.product.id
        );
        document.body.classList.add("product-open");
      } else {
        document.body.classList.remove("product-open");
      }
    },
  },
  computed: {
    ...sync("showProduct/*"),
  },
  methods: {
    ...call("showProduct/*"),
    addProduct(id) {
      this.$emit("addProduct", id);
    },
  },
  mounted() {
    window.onhashchange = (e) => {
      if (!e.target.location.hash.includes("#product-")) {
        this.close();
        document.body.classList.remove("product-open");
      }
    };
  },
};
</script>

<style>
body.product-open {
  overflow: hidden;
  width: 100%;
}
</style>

<style lang="scss" scoped>
/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 4; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  width: 100%;
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.3s;
}

.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

.slidefade-leave-active,
.slidefade-enter-active {
  transition: 0.4s, opacity 0.5s;
}

.slidefade-enter {
  transform: translate(100%, 0);
  opacity: 0;
}
.slidefade-leave-to {
  opacity: 0;
  transform: translate(100%, 0);
}
</style>