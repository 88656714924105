<template>
  <div :class="[{ show: open }, 'modal']">
    <!-- Modal content -->
    <div class="modal-content">
        <div class="modal-title">{{title}}</div>
        <div class="modal-message">
            {{message}}
        </div>
        <div class="button-wrapper">
            <div class="button outline" @click.stop="$emit('close')">ปิดหน้าต่าง</div>
            <div class="button" @click="$emit('confirm')">ยืนยัน</div>
        </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
    props: { 
        title:String,
        message:String,
        open: Boolean,
    },
};
</script>
<style lang="scss" scoped>
.button-wrapper{
    display: flex;
    margin-top: 24px;
}
.button{
    padding: 16px 0;
    flex: 1;
    margin: 5px;
    text-align: center;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 8px;
    font-weight: bold;

    &.outline{
      border:1px solid #cccccc;
      background-color: #fff;
      color: #434343;
    }
}

/* The Modal (background) */
.modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  // padding-top: 100px; /* Location of the box */
  left: 0;
  opacity: 0;
  top: -100%;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  //   animation-direction: reverse;
  //   -webkit-animation-name: animatehide;
  //   -webkit-animation-duration: 0.4s;
  //   animation-name: animatehide;
  //   animation-duration: 0.4s;
  &.show {
    opacity: 1;
    top: 0;
    
    > .modal-content {
      //   animation-direction: reverse;
      -webkit-animation-name: animatetop;
      -webkit-animation-duration: 0.4s;
      animation-name: animatetop;
      animation-duration: 0.4s;

        > .modal-title {
            font-weight: bold;
            font-size: 20px;
            text-align: center;
            margin-bottom: 24px;
        }
        > .modal-message{
            line-height: 28px;
            white-space: pre-line;
        }
    }
  }
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  border-radius: 10px;
  position: relative;
}

/* The Close Button */
.close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* Add Animation */
@-webkit-keyframes animatehide {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -300px;
    opacity: 0;
  }
}

@keyframes animatehide {
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -300px;
    opacity: 0;
  }
}
</style>