<template>
  <div class="settingMain">
    <NavbarBack title="ตั้งค่าที่อยู่จัดส่ง" />
    <div class="button-container">
      <div class="button" @click="onAddClick">เพิ่มที่อยู่</div>
    </div>
    <div class="container">
      <DefaultDisplayStage
        :fetching="fetching"
        :error="error"
        :nodata="nodata"
      />
      <AddressList
        :data="addresses"
        :editItemClick="onEditClick"
        :setDefault="setDefault"
        :isCheckout="isCheckout"
      />
    </div>
  </div>
</template>

<script>
import { GET, POST } from "@/utils/http";

import NavbarBack from "@/components/NavbarBack";
import AddressList from "@/views/components/AddressList";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";

export default {
  components: { NavbarBack, AddressList, DefaultDisplayStage },
  props: { isCheckout: Boolean },
  data() {
    return {
      addresses: [],
      fetching: false,
      error: false,
    };
  },
  computed: {
    nodata() {
      return !this.fetching && this.addresses.length <= 0 && !this.error;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getAddresses();
    });
  },
  mounted() {
    // this.getAddresses();
  },
  methods: {
    async getAddresses() {
      try {
        this.fetching = true;
        const { status, body } = await GET(
          `/api/shops/${this.$myShop.id}/addresses`
        );

        if (status !== 200) {
          throw "not ok";
        }

        this.addresses = body.data;
      } catch (err) {
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
    onAddClick() {
      this.$router.push({
        path: `/settings/address-detail`,
      });
    },
    onEditClick(id) {
      this.$router.push({
        path: `/settings/address-detail/${id}`,
      });
    },
    async setDefault(addrId, checked) {
      console.log(addrId, checked);

      if (!checked) {
        return;
      }

      window.$alert("กำลังอัปเดต...", "loading");

      if (this.controller) {
        this.controller.abort();
      }

      this.controller = new AbortController();
      const signal = this.controller.signal;
      try {
        const { status } = await POST(
          `/api/shops/${this.$myShop.id}/addresses/${addrId}/default`,
          null,
          null,
          signal
        );

        if (status !== 200) {
          throw "not ok";
        }

        if (this.isCheckout) {
          this.$router.back();
        }
      } catch (err) {
        await window.$alert(
          "พบปัญหา กรุณาลองอีกครั้ง<br>หรือติดต่อเจ้าหน้าที่"
        );
        this.addresses = [];
        this.getAddresses();
      } finally {
        this.controller = null;
        window.$alert(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settingMain {
  padding-top: 40px;
  background: #fff;
  height: 100%;
}
.container {
  padding: 0 16px;
  height: calc(100% - 60px);
}
.title {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-button {
  color: var(--primary-color);
  cursor: pointer;
  margin-top: 4px;
  background: #ececec;
  padding: 14px 8px;
  border-radius: 8px;
  height: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  padding: 0 16px;
  padding-bottom: 8px;
  justify-content: flex-end;
}
.button {
  cursor: pointer;
  background: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 16px;
  border-radius: 8px;
  height: 20px;
  font-size: 13px;
}
</style>