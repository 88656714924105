<template>
  <div class="main">
    <NavbarBack title="แก้ไขผู้ใช้งานหลังบ้าน(ตัวเอง)"/>
    <div class="container">
        <div class="title">
            อีเมลปัจจุบัน*
        </div>
        <input type="text" v-model="email" readonly/>
        <div class="description">
          หากลืมรหัสผ่านหรือยังไม่เคยตั้งรหัสผ่าน กรุณากดปุ่ม "ลืมรหัสผ่าน" เพื่อตั้งค่า
        </div>
    </div>

    <div class="bottom">
        <div class="button" @click="changeEmailClick">
            ตั้งค่า/เปลี่ยนอีเมลล์
        </div>
        <div class="button" @click="forgetPasswordClick">
            ลืมรหัสผ่าน
        </div>
    </div>

    <ModalConfirm title="ยืนยันการลืมรหัสผ่าน" :open="showForgetPassword"
      @close="showForgetPassword=false"
      @confirm="confirmForgetPassword"
      :message="`ลิงก์จะถูกส่งไปยังอีเมล
                ${this.email}
                เพื่อสร้างรหัสผ่านใหม่`"
    />

  </div>
</template>

<script>
import { GET } from "@/utils/http";
import NavbarBack from "@/components/NavbarBack";
import ModalConfirm from "@/components/ModalConfirm";
const API_URL = process.env.VUE_APP_API_URL

export default {
  components: { NavbarBack,ModalConfirm},
  data() {
    return {
      email:"",
      status:1,
      showForgetPassword:false
    };
  },
  methods: {
    changeEmailClick(){
      this.$router.push({
        path: `/settings/user-setting/change-email`,
      });
    },
    changePasswordClick(){
        this.$router.push({
          path: `/settings/user-setting/change-password`,
        });
    },
    forgetPasswordClick(){
      this.showForgetPassword = true
    },
    async getMyProfile() {
      try {
        window.loading(true);
        const { status,body} = await GET(
          `/api/user-profile`
        );

        if (status !== 200) {
          throw "not ok";
        }
        
        this.email = body.email

      } catch (err) {
        this.error = true;
      } finally {
        window.loading(false);
      }
    },
    async confirmForgetPassword(){
      const reqBody = {
        email: this.email,
      };

      const res = await fetch(`${API_URL}/api/forgot-password`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${window.$authToken}`,
        },
        body: JSON.stringify(reqBody)
      })
      if(res.status!==200){
        window.$alert("การส่งลิ้งค์สำหรับสร้างรหัสใหม่ขัดข้อง");
      }
      this.showForgetPassword = false;
      this.$router.push({
        path: `/settings/user-setting`,
      });
    },
  },
  mounted() {
    this.getMyProfile();
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding-top: 40px;
  background: #fff;
  height: 100%;
  
}
.description{
    font-style: italic;
    line-height: 28px;
    color:rgb(102, 102, 102);
    margin-bottom: 10px;
}
.container{
  padding:  0 16px;
}
.title {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status{
  margin-top: 10px;
  margin-bottom: 10px;

  >b{
    font-weight: bold;
  }
}
input {
  width: 100%;
  height: 42px;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #b9b9b9;
  background: #ffffffbd;

  &:read-only{
    background-color: #f3f3f3ff;
  }
}
.bottom{
    position: absolute;
    width: 100%;
    padding: 0 16px;
    bottom: 24px;
    >.button:not(:last-child){
      margin-bottom: 8px;
    }
}
.button{
    padding: 16px 0;
    text-align: center;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 8px;
    font-weight: bold;

    &.outline{
      border:1px solid #cccccc;
      background-color: #fff;
      color: #434343;
    }
}
</style>