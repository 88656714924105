<template>
  <div class="h100">
    <div class="cardWrap">
      <div
        class="card"
        v-for="item in data"
        :key="item.id"
        @click="showProduct(item)"
      >
        <div :class="[productCompanyGroupName(item), 'imgWrap']">
          <div v-if="item.addons" class="strip">
            <span>โปรโมชั่น</span>
          </div>
          <img
            v-if="item.productPhotos"
            :src="
              item.productPhotos.length > 0
                ? item.productPhotos[0].url
                : $placeholderImg
            "
          />
          <img v-else :src="$placeholderImg" />
        </div>
        <div class="detailWrap">
          <div>{{ item.name }}</div>
          <!-- <div>{{ item.description }}</div> -->
          <ProductStatus :product="item" />
          <div class="priceWrap">
            <span class="price">
              <span v-if="calStep(item)"
                >{{ calStep(item).min.toLocaleString() }} ~
                {{ calStep(item).max.toLocaleString() }}</span
              >
              <span v-else>{{ item.salePrice.toLocaleString() }}</span>
            </span>
            <span class="unitPrice">บาท</span>
            <span v-if="item.price !== item.salePrice" class="fromPrice"
              >{{ item.price }} <span class="">บาท</span></span
            >
            <span class="unit"> / {{ item.unit }}</span>
          </div>
        </div>
        <div class="actionContain">
          <div class="actionWrap">
            <div class="editBtn">แก้ไข</div>
          </div>
        </div>
      </div>
    </div>
    <scroll-loader
      :loader-method="() => $emit('scrollEnd')"
      :loader-disable="fetching || endList || error"
      :loader-size="0"
      class="reset"
    >
    </scroll-loader>
    <ProductListLoader v-if="fetching" />
    <DefaultDisplayStage :error="error" :nodata="nodata" />
    <div v-if="products.length > 0" style="height: 100px"></div>
  </div>
</template>

<script>
import { call } from "vuex-pathify";
import ProductListLoader from "@/components/loader/ProductListLoader";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";
import ProductStatus from "@/components/ProductStatus";
import { calStep } from "@/utils/product";

export default {
  props: {
    products: Array,
    fetching: Boolean,
    endList: Boolean,
    error: Boolean,
    nodata: Boolean,
  },
  components: { ProductListLoader, DefaultDisplayStage, ProductStatus },
  computed: {
    data() {
      return this.products.filter((e) => e.deleted !== true);
    },
  },
  methods: {
    calStep: calStep,
    ...call("showProduct/*"),
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/productCardList.scss";
</style>