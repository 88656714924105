<template>
  <div class="card">
    <div class="field">
        <span class="label">ชื่อ-นามสกุล</span>
        {{data.name}}
    </div>
    <div class="field">
        <span class="label">ชื่อร้าน</span>
        {{data.shop_name}}
    </div>
    <div class="field">
        <span class="label">รหัสร้าน</span>
        {{data.shop_code}}
    </div>
    <!-- <div class="field">
        <span class="label">ความสัมพันธ์</span>
        {{data.relationship}}
    </div> -->
  </div>
</template>

<script>
export default {
  name: "UserInfo",
  props: { 
      data: Object,
    },
}
</script>

<style lang="scss" scoped>
.field:not(:last-child){
    margin-bottom: 10px;
}
.card{
    padding: 8px 12px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
}

.label{
    font-weight: bold;
}
</style>