<template>
  <div class="importProductDetail">
    <div class="nav">
      <div class="navInner">
        <!-- <img @click="back()" src="@/assets/img/left-arrow.svg" alt="" /> -->
        <div @click.stop="back()" class="backBtn mask-image"></div>

        <!-- <span class="title">นำเข้าสินค้า Thaibev</span> -->
      </div>
    </div>
    <div class="productDetail">
      <div class="imgWrap">
        <div v-if="product.addons" class="strip">
          <span>โปรโมชั่น</span>
        </div>
        <img
          v-if="product.productPhotos && product.productPhotos.length > 0"
          :src="product.productPhotos[0].url"
        />
        <img v-else :src="$placeholderImg" />
      </div>
      <div class="detailWrap">
        <div class="productTitle">{{ product.name }}</div>
        <ProductStatus :product="product" />

        <div class="priceWrap">
          <span class="price">
            <span v-if="calStep(product)"
              >{{ calStep(product).min.toLocaleString() }} ~
              {{ calStep(product).max.toLocaleString() }}</span
            >
            <span v-else>{{ product.salePrice.toLocaleString() }}</span>
          </span>
          <span class="unitPrice">บาท</span>
          <span v-if="product.price !== product.salePrice" class="fromPrice"
            >{{ product.price }} <span class="unitPrice">บาท</span></span
          >
          <span class="unit"> / {{ product.unit }}</span>
        </div>
        <div v-if="product.addons" class="productSubTitle">
          <div class="desTitle"><b>รายละเอียดโปรโมชั่น</b></div>
          {{ product.addonsDetail }}
        </div>
        <div class="productSubTitle">
          <div class="desTitle"><b>รายละเอียดสินค้า</b></div>
          {{ product.description }}
        </div>
        <StepPriceDetail :product="product" />
      </div>
      <div style="height: 100px"></div>
    </div>
    <div class="actionWrap">
      <div class="editBtn btn" @click="goToEdit(product.id)">แก้ไขสินค้า</div>

      <!-- <div v-if="product.deleting" class="addingBtn btn">
            <img src="@/assets/img/loader.svg" width="15" alt="" />
            กำลังลบ...
          </div> -->
      <div class="removeBtn btn" @click="removeProduct(product.id)">
        ลบสินค้า
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import { DELETE } from "@/utils/http";
import StepPriceDetail from "@/components/StepPriceDetail";
import { calStep, calUnitPrice } from "@/utils/product";
import ProductStatus from "@/components/ProductStatus";

export default {
  props: { product: Object },
  components: { StepPriceDetail, ProductStatus },
  data() {
    return {};
  },
  methods: {
    calStep: calStep,
    calUnitPrice: calUnitPrice,
    back() {
      !isEmpty(window.$forceBackTo)
        ? this.$router.push(window.$forceBackTo)
        : this.$router.back();
    },
    async removeProduct(pid) {
      if (!(await window.$alert("ต้องการลบหรือไม่?", "confirm"))) {
        return;
      }

      try {
        window.loading(true);

        const { status } = await DELETE(`/api/products/${pid}`);

        if (status !== 204) {
          throw "not ok";
        }

        window.loading(false);
        // this.$set(this.product, "deleted", true);
        await window.$alert("ลบสินค้าสำเร็จ");
        this.$emit("change");
        this.back();
      } catch (err) {
        window.loading(false);
        window.$alert("ไม่สามารถดำเนินการได้ในขณะนี้");
        // this.$set(this.product, "deleting", false);
      }
    },
    goToEdit(productId) {
      // fix change router still modal class
      document.body.classList.remove("product-open");
      this.$router.push({
        path: `/product/${productId}/edit`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.importProductDetail {
  background: #fff;
  height: 100%;
}

.nav {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 45px;
}

.navInner {
  background: #ffffffc2;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 6px;
  border-radius: 7px;
}

.navInner > img {
  width: 25px;
}

.title {
  padding-left: 8px;
}

img {
  max-width: 100%;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.imgWrap {
  img,
  .img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 500px;
    aspect-ratio: 1/1;
  }
  .strip {
    width: 100px;
    height: 100px;
    position: absolute;
    /*background: green;*/
    top: -24px;
    left: -52px;
    transform: rotate(-45deg);
    span {
      position: absolute;
      bottom: 0;
      display: block;
      font-size: 18px;
      color: #fff;
      background: #f43131;
      width: 141px;
      height: 32px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.productTitle {
  font-size: clamp(18px, 5vw, 24px);
  font-weight: bold;
  color: dimgrey;
  margin-bottom: 4px;
}

.productSubTitle {
  margin: 20px 0;
  font-size: 16px;
  .desTitle {
    margin: 10px 0;
    color: #5c5c5c;
    font-size: 15px;
  }
}

.actionWrap {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 65px;
  padding: 0 10px;
  z-index: 1;
  padding-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  background: rgba(255, 255, 255, 0.6);
  .btn {
    position: relative;
    margin: 10px 5px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    max-width: 350px;
  }
}

.addBtn {
  background: var(--primary-color);
  color: #fff;
}

.addedBtn {
  background: #a0a0a0;
  color: #fff;
}

.addingBtn {
  background: #afafaf;
  color: #fff;
  > img {
    padding-right: 5px;
  }
}

.removeBtn {
  background: #be0000;
  color: #fff;
  // margin: 10px auto;
  width: 100%;
}

.editBtn {
  border: 1px solid #afafaf;
  width: 170%;
}

.priceWrap {
  margin-top: 10px;
}

.detailWrap {
  font-size: 12px;
  padding: 4px 15px 4px 15px;
  z-index: 1;
  position: relative;
  line-height: 17px;
}

.fromPrice {
  font-size: 16px;
  margin-left: 6px;
  color: #989898;
  text-decoration: line-through;
}

.price {
  font-size: 20px;
  padding-right: 4px;
  color: var(--primary-color);
}

.productDetail {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  min-height: 100%;
  height: 100%;
}

.backBtn {
  &.mask-image {
    width: 28px;
    height: 28px;
    mask-image: url("~@/assets/img/left-arrow.svg");
  }
}
</style>