<template>
  <div class="h100">
    <div v-if="categoryData.length > 0">
      <Category v-on:click="categoryClick" :data="categoryData" />
      <div style="height: 100px"></div>
    </div>
    <DefaultDisplayStage :fetching="fetching" :error="error" :nodata="nodata" />
  </div>
</template>

<script>
import Category from "@/views/components/Category";
import { GET } from "@/utils/http";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";

export default {
  components: { Category, DefaultDisplayStage },
  data() {
    return {
      // categoryData: [
      //   {
      //     name: "เครื่องดื่ม",
      //     id: "1",
      //   },
      //   {
      //     name: "ขนมซอง",
      //     id: "2",
      //   },
      //   {
      //     name: "อาหารกระป๋อง",
      //     id: "3",
      //   },
      // ],
      categoryData: [],
      fetching: false,
      error: false,
    };
  },
  computed: {
    nodata() {
      return !this.fetching && this.categoryData.length <= 0 && !this.error;
    },
  },
  methods: {
    categoryClick(item) {
      this.$router.push({
        path: `/products/category/${item.name}/${item.id}`,
      });
    },
    async getCategory() {
      try {
        this.fetching = true;

        const shopId = this.$isSeller ? this.$myShop.id : this.$sellerShop.id;
        const { status, body } = await GET(
          `/api/${shopId}/categories?page=1&limit=100&query=`
        );

        if (status !== 200) {
          throw "not ok";
        }
        this.categoryData = body.data;
      } catch (err) {
        console.error(err);
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
  },
  mounted() {
    this.getCategory();
  },
};
</script>
<style lang="scss" scoped>
</style>