<template>
  <div class="settingMain">
    <NavbarBack title="ตั้งค่าผู้ใช้งาน" />
    <div class="container">
      <div class="title">ข้อมูลผู้ใช้</div>
      <UserInfo :data="user_info" />

      <br />

      <div class="title">ผู้ใช้งานเว็บหลังบ้าน(ตัวเอง)</div>
      <div class="item">
        <div class="data">
          <div v-if="this.email == null" class="nodata">ไม่พบผู้ใช้งาน</div>
          <div v-else class="email">{{ email }}</div>
          <div class="button inlist" @click="ownerEdit">
            <img src="@/assets/img/edit-white.svg" />
            แก้ไข
          </div>
        </div>
      </div>

      <br />

      <div class="title">
        ผู้ใช้งานเว็บหลังบ้าน(พนักงาน)
        <div class="title-button" @click="employeeInviteClick">
          <b>+</b>
          เชิญผู้ใช้
        </div>
      </div>
      <UserSettingList :data="employee_backend" :itemClick="employeeClick" />
    </div>
  </div>
</template>

<script>
import { GET } from "@/utils/http";
import NavbarBack from "@/components/NavbarBack";
import UserSettingList from "@/views/components/UserSettingList";
import UserInfo from "@/views/components/UserInfo";
import jwtDecode from "jwt-decode";

export default {
  components: { NavbarBack, UserSettingList, UserInfo },
  data() {
    return {
      fetching: false,
      error: false,
      email: "",
      invite_list: [],
      employee_backend: [],
    };
  },
  computed: {
    user_info() {
      const decoded = jwtDecode(window.$apiToken);
      return {
        id: decoded.u.id,
        name: decoded.u.name,
        shop_code: decoded.s[0].code,
        shop_name: decoded.s[0].name,
        relationship: "-",
      };
    },
    self_backend() {
      return [];
    },
  },
  methods: {
    async getPendingList() {
      try {
        const decoded = jwtDecode(window.$apiToken);

        this.fetching = true;
        const { status, body } = await GET(
          `/api/shops/${decoded.s[0].id}/invites`
        );

        if (status !== 200) {
          throw "not ok";
        }

        this.employee_backend = body.map((obj) => ({
          id: obj.id,
          user: obj.shopUser?.User,
          email: obj.email,
          is_expired: new Date(obj.expiredAt) < new Date(),
          status: obj.status,
        }));
      } catch (err) {
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
    async getMyProfile() {
      try {
        window.loading(true);
        const { status, body } = await GET(`/api/user-profile`);

        if (status !== 200) {
          throw "not ok";
        }

        this.email = body.email;
      } catch (err) {
        this.error = true;
      } finally {
        window.loading(false);
      }
    },
    setEmailClick() {
      this.$router.push({
        path: `/settings/user-setting/create`,
      });
    },
    ownerEdit() {
      if (this.email == null) {
        this.$router.push({
          path: `/settings/user-setting/change-email`,
        });
      } else {
        this.$router.push({
          path: `/settings/user-setting/edit`,
        });
      }
    },
    employeeInviteClick() {
      this.$router.push({
        path: `/settings/user-setting/employee-invite`,
      });
    },
    employeeClick(item) {
      this.$router.push({
        path: `/settings/user-setting/employee-edit/${item.id}`,
      });
    },
    fetchData() {
      this.getMyProfile();
      this.getPendingList();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchData();
    });
  },
  mounted() {
    // this.getMyProfile();
    // this.getPendingList();
  },
};
</script>
<style lang="scss" scoped>
.settingMain {
  padding-top: 40px;
  background: #fff;
  height: 100%;
}
.container {
  padding: 0 16px;
}
.title {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-button {
  color: var(--primary-color);
  cursor: pointer;
  margin-top: 4px;
  background: #ececec;
  padding: 14px 8px;
  border-radius: 8px;
  height: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  > b {
    font-size: 19px;
    margin-right: 4px;
    margin-top: -4px;
  }
}
.button {
  padding: 16px 0;
  text-align: center;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 8px;
  font-weight: bold;
  margin-top: 8px;
  &.inlist {
    margin-top: 0;
    width: 72px;
    font-size: 14px;
    padding: 8px 0;
  }
}

.item {
  display: flex;
  padding: 8px 12px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d8d8d8;
  min-height: 40px;
  user-select: none;
  img {
    width: 15px;
  }

  .data {
    font-weight: bold;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    > .email {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .nodata {
    padding: 12px 0;
    font-weight: normal;
    font-style: italic;
  }
}
</style>