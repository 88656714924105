<template>
  <div v-if="fetching || error || nodata">
    <DefaultDisplayStage
      v-if="error || nodata"
      :error="error"
      :nodata="nodata"
    />
    <div class="productDetail" v-if="fetching">
      <div class="imgWrap skeleton"></div>
      <div class="text skeleton" style="width: 40%"></div>
      <div class="text skeleton" style="width: 80%"></div>
      <div class="text skeleton" style="width: 100px"></div>
    </div>
  </div>
</template>

<script>
import DefaultDisplayStage from "@/components/DefaultDisplayStage";

export default {
  props: {
    fetching: Boolean,
    error: Boolean,
    nodata: Boolean,
  },
  components: { DefaultDisplayStage },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/skeleton.scss";

.imgWrap {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  aspect-ratio: 1/1;
}

.text {
  border-radius: 6px;
  overflow: hidden;
  height: 20px;
  margin: 10px;
}
</style>