<template>
  <div class="settingMain">
    <NavbarBack :title="title" />
    <div class="container">
      <label>ชื่อ</label>
      <input v-model="name" type="text" placeholder="ชื่อที่อยู่จัดส่ง" />

      <label>ผู้ติดต่อ</label>
      <input v-model="contact_person" type="text" placeholder="ชื่อ-นามสกุล" />

      <label>เบอโทรศัพท์</label>
      <input v-model="tel" type="text" placeholder="000-000-0000" />

      <label>จังหวัด</label>
      <select
        v-model="provinceId"
        :disabled="province_list.length == 0"
        @change="
          (e) => {
            this.provinceId = e.target.value;
            this.districtId = null;
            this.subDistrictId = null;
            this.postcode = null;
            this.fetchDistrict();
            this.$forceUpdate();
          }
        "
      >
        <option disabled :value="null">เลือกจังหวัด</option>
        <option
          v-for="province in province_list"
          :key="province.id"
          :value="province.id"
        >
          {{ province.name }}
        </option>
      </select>

      <label>อำเภอ</label>
      <select
        v-model="districtId"
        :disabled="district_disable"
        @change="
          (e) => {
            this.districtId = e.target.value;
            this.subDistrictId = null;
            this.postcode = null;
            this.fetchSubDistrict();
            this.$forceUpdate();
          }
        "
      >
        <option disabled :value="null">เลือกอำเภอ</option>
        <option
          v-for="district in district_list"
          :key="district.id"
          :value="district.id"
        >
          {{ district.name }}
        </option>
      </select>

      <label>ตำบล</label>
      <select
        v-model="subDistrictId"
        :disabled="sub_district_disable"
        @change="
          (e) => {
            this.subDistrictId = e.target.value;
            this.postcode = null;

            this.fetchPostCode();
            this.$forceUpdate();
          }
        "
      >
        <option disabled :value="null">เลือกตำบล</option>
        <option
          v-for="sub_district in sub_district_list"
          :key="sub_district.id"
          :value="sub_district.id"
        >
          {{ sub_district.name }}
        </option>
      </select>

      <label>รหัสไปรษณีย์</label>
      <select
        v-model="postcode"
        :disabled="postcode_disable"
        @change="
          (e) => {
            this.postcode = e.target.value;
          }
        "
      >
        <option disabled :value="null">รหัสไปรษณีย์</option>
        <option
          v-for="postcode in postcode_list"
          :key="postcode.postcode"
          :value="postcode.postcode"
        >
          {{ postcode.postcode }}
        </option>
      </select>

      <label>รายละเอียดที่อยู่</label>
      <textarea v-model="detail" rows="3" placeholder="ข้อความ" />
    </div>
    <div class="bottom">
      <div v-show="is_edit" class="button outline" @click="onDelete">
        ลบข้อมูล
      </div>
      <div class="button" @click="onSave">บันทึกข้อมูล</div>
    </div>
  </div>
</template>

<script>
import NavbarBack from "@/components/NavbarBack";
import { GET, POST, DELETE } from "@/utils/http";

export default {
  components: { NavbarBack },
  props: {
    id: String,
  },
  data() {
    return {
      is_edit: false,
      fetching: false,
      name: "",
      contact_person: "",
      tel: "",
      provinceId: null,
      districtId: null,
      subDistrictId: null,
      postcode: null,
      detail: "",
      province_list: [],
      district_list: [],
      sub_district_list: [],
      postcode_list: [],
    };
  },
  computed: {
    title() {
      return this.is_edit ? "แก้ไขที่อยู่จัดส่ง" : "เพิ่มที่อยู่จัดส่ง";
    },
    district_disable() {
      return (
        this.district_list.length == 0 ||
        this.provinceId == "" ||
        this.provinceId == null
      );
    },
    sub_district_disable() {
      return (
        this.sub_district_list.length == 0 ||
        this.districtId == "" ||
        this.districtId == null
      );
    },
    postcode_disable() {
      return (
        this.postcode_list.length == 0 ||
        this.subDistrictId == "" ||
        this.subDistrictId == null
      );
    },
  },
  methods: {
    async fetchData() {
      if (this.id != null) {
        this.is_edit = true;
        this.fetching = true;
        const { status, body } = await GET(
          `/api/shops/${this.$myShop.id}/addresses/${this.id}`
        );
        if (status !== 200) {
          throw "not ok";
        }
        this.name = body.name;
        this.contact_person = body.contact_person;
        this.tel = body.tel;
        this.provinceId = body.provinceId;
        this.districtId = body.districtId;
        this.subDistrictId = body.subDistrictId;
        this.postcode = body.postcode;
        this.detail = body.detail;
      } else {
        this.is_edit = false;
      }
    },
    async fetchProvince() {
      const { status, body } = await GET("/api/provinces");
      if (status !== 200) {
        throw "not ok";
      }
      this.province_list = body.data;
    },
    async fetchDistrict() {
      this.district_list = [];
      if (this.provinceId == null) return;
      const { status, body } = await GET(
        `/api/districts?provinceId=${this.provinceId}`
      );
      if (status !== 200) {
        throw "not ok";
      }
      this.district_list = body.data;
    },
    async fetchSubDistrict() {
      this.sub_district_list = [];
      if (this.provinceId == null || this.districtId == null) return;
      const { status, body } = await GET(
        `/api/sub-districts?provinceId=${this.provinceId}&districtId=${this.districtId}`
      );
      if (status !== 200) {
        throw "not ok";
      }
      this.sub_district_list = body.data;
    },

    async fetchPostCode() {
      this.postcode_list = [];
      if (
        this.provinceId == null ||
        this.districtId == null ||
        this.subDistrictId == null
      )
        return;
      const { status, body } = await GET(
        `/api/postcodes?provinceId=${this.provinceId}&districtId=${this.districtId}&subdistrictId=${this.subDistrictId}`
      );
      if (status !== 200) {
        throw "not ok";
      }
      this.postcode_list = body.data;
      if (body.data.length === 1) {
        this.postcode = body.data[0].postcode;
      }
    },

    async onSave() {
      if (!(await window.$alert("ยืนยันการบันทึก?", "confirm"))) {
        return;
      }

      try {
        const reqBody = {
          name: this.name,
          contact_person: this.contact_person,
          tel: this.tel,
          provinceId: parseInt(this.provinceId),
          districtId: parseInt(this.districtId),
          subDistrictId: parseInt(this.subDistrictId),
          postcode: this.postcode.toString(),
          detail: this.detail,
        };

        const url = `/api/shops/${this.$myShop.id}/addresses`;

        if (this.is_edit) {
          await POST(`${url}/${this.id}`, reqBody);
        } else {
          await POST(url, reqBody);
        }
        this.$router.back();
      } catch (error) {
        window.$alert("ขออภัย โปรดตรวจสอบข้อมูลให้ครบถ้วน!");
        return;
      }
    },
    async onDelete() {
      if (!(await window.$alert("ยืนยันการลบ?", "confirm"))) {
        return;
      }
      await DELETE(`/api/shops/${this.$myShop.id}/addresses/${this.id}`);
      this.$router.back();
    },
    async init() {
      this.name = "";
      this.contact_person = "";
      this.tel = "";
      this.provinceId = null;
      this.districtId = null;
      this.subDistrictId = null;
      this.postcode = null;
      this.detail = "";
      await this.fetchData();
      if (this.province_list.length === 0) this.fetchProvince();
      this.fetchDistrict();
      this.fetchSubDistrict();
      this.fetchPostCode();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.init();
    });
  },
  // async mounted() {
  //   this.fetchProvince();
  // },
};
</script>
<style lang="scss" scoped>
.settingMain {
  padding-top: 40px;
  background: #fff;
  height: 100%;
}
.container {
  margin-top: 8px;
  padding: 0 16px;
}
label {
  display: flex;
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: bold;
}
input,
textarea {
  font-family: "Sarabun", sans-serif;
  width: 100%;
  min-height: 40px;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #b9b9b9;
  background: #ffffffbd;
  resize: none;
}
.bottom {
  width: 100%;
  padding: 0 16px;
  padding-bottom: 30px;
  margin: 16px 0;
  > .button:not(:last-child) {
    margin-bottom: 8px;
  }
}

.button {
  padding: 16px 0;
  text-align: center;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 8px;
  font-weight: bold;
  &.outline {
    border: 1px solid #cccccc;
    background-color: #fff;
    color: #434343;
  }
}

select {
  width: 100%;
  padding: 9px 4px;
  /* padding-right: 16px; */
  font-size: 13pt;
  border-radius: 4px;
  background: white;
  border-color: #b9b9b9;
}
</style>