<template>
  <div class="main">
    <NavbarBack title="เปลี่ยนรหัสผ่าน"/>
    <div class="container">
        
        <div class="title">
            รหัสผ่านปัจจุบัน*
        </div>
        <input type="password" v-model="current_password"/>

        <div class="title">
            รหัสผ่านใหม่*
        </div>
        <input type="password" v-model="new_password"/>

        <div class="title">
            ยืนยันรหัสผ่านใหม่*
        </div>
        <input type="password" v-model="retype_new_password"/>
    </div>

    <div class="bottom">
        <div class="button" @click="onSave">
            บันทึกข้อมูล
        </div>
    </div>
    
  </div>
</template>

<script>

import NavbarBack from "@/components/NavbarBack";
import { POST } from "@/utils/http";

export default {
  components: { NavbarBack},
  data() {
    return {
      current_password:"",
      new_password:"",
      retype_new_password:"",
    };
  },
  methods: {
    async onSave(){
      if(this.new_password!==this.retype_new_password){
        alert("retype fail")
        return;
      }

      const reqBody = {
        oldPassword: this.current_password,
        newPassword: this.new_password,
        confirmPassword: this.retype_new_password,
      };

      const url = `/api/change-password`;
      
      await POST(url, reqBody);

      this.$router.back();
    }
    
  },
  mounted() {
    
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding-top: 40px;
  background: #fff;
  height: 100%;
  
}

.container{
  padding:  0 16px;
}
.title {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.status{
  margin-top: 10px;
  margin-bottom: 10px;

  >b{
    font-weight: bold;
  }
}
input {
  width: 100%;
  height: 42px;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #b9b9b9;
  background: #ffffffbd;

  &:read-only{
    background-color: #f3f3f3ff;
  }
}
.bottom{
    position: absolute;
    width: 100%;
    padding: 0 16px;
    bottom: 24px;
    >.button:not(:last-child){
      margin-bottom: 8px;
    }
}
.button{
    padding: 16px 0;
    text-align: center;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 8px;
    font-weight: bold;

    &.outline{
      border:1px solid #cccccc;
      background-color: #fff;
      color: #434343;
    }
}
</style>