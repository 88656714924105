<template>
  <ManageProductList ref="productList" />
</template>

<script>
import ManageProductList from "./ManageProductList";

export default {
  components: { ManageProductList },
  mounted() {
    console.log("mounted: promotion");
  },
};
</script>