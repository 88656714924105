
<template>
  <div class="manageProductPage">
    <div :class="['head']">
      <NavBar />
      <div :class="[{ hide: hideMenu }, 'collapse']">
        <Menu :class="['menu']" />
      </div>
    </div>
    <div :class="[{ hasFilters: showFilters }, 'content']">
      <!-- <keep-alive> -->
      <!-- <router-view :key="rf" /> -->
      <router-view :key="tickChange" />
      <!-- </keep-alive> -->
    </div>
    <!-- End card wrap -->
    <ManageProductModal
      ref="manageProductModal"
      @change="productChange"
      :key="rf"
    />
  </div>
</template>

<script>
import NavBar from "./NavBar";
import Menu from "./Menu";
import ManageProductModal from "./ManageProductModal";

export default {
  name: "manageProductsPage",
  components: { NavBar, Menu, ManageProductModal },
  data() {
    return {
      hideHead: false,
      hideMenu: false,
      saveScroll: {},
      rf: 0,
      tickChange: 0,
    };
  },
  computed: {
    showFilters() {
      return this.$route.meta.filters === true;
    },
  },
  methods: {
    productChange() {
      this.tickChange++;
    },
  },
  mounted() {
    if (this.$route.query.rf === "1") {
      this.rf++;
    }
    // hide nav when scroll
    // const mainElm = window;
    // let prevScrollpos = 0;
    // window.onscroll = () => {
    //   if (prevScrollpos - 15 > window.scrollY) {
    //     // this.hideHead = false;
    //     this.hideMenu = false;
    //     prevScrollpos = window.scrollY;
    //   } else if (prevScrollpos + 50 < window.scrollY) {
    //     // this.hideHead = true;
    //     this.hideMenu = true;
    //     prevScrollpos = window.scrollY;
    //   }
    // };
  },
};
</script>
<style lang="scss" scoped>
.manageProductPage {
  background: #fff;
  min-height: 100%;
  position: relative;
  height: 100%;
}

.collapse {
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 45px;
  transition: top 0.3s;
}
.collapse.hide {
  top: -95px;
}

.menu {
  // position: absolute;
  // width: 100%;
  // z-index: -1;
  // top: 45px;
  // transition: top 0.5s;
}

.menu.hide {
  // top: -95px;
}

.content {
  padding: 75px 0 70px 0;
  height: 100%;
  // height: 100%;
  // overflow-y: scroll;
  // height: 90vh;
  &.hasFilters {
    padding: 120px 0 70px 0;
  }
}
</style>
