<template>
  <div
    v-if="product.productStepPrices && product.productStepPrices.length > 0"
    class="stepPriceDetail"
  >
    <b>ราคาสเต็ป</b>
    <div>
      <table>
        <thead>
          <th>จำนวน ({{ product.unit }})</th>
          <th>ราคา (บาท/{{ product.unit }})</th>
        </thead>
        <tbody>
          <tr v-if="product.productStepPrices[0].min > 1">
            <td>
              1
              <span v-if="product.productStepPrices[0].min - 1 > 1"
                >- {{ product.productStepPrices[0].min - 1 }}</span
              >
            </td>
            <td>
              {{ product.salePrice.toLocaleString() }}
            </td>
          </tr>

          <template v-for="(step, index) of product.productStepPrices">
            <tr
              v-if="index !== product.productStepPrices.length - 1"
              :key="index"
            >
              <td>{{ step.min }} - {{ step.max }}</td>
              <td>
                {{ step.price.toLocaleString() }}
              </td>
            </tr>
            <tr
              v-if="index === product.productStepPrices.length - 1"
              :key="index"
            >
              <td>{{ step.min }} ขึ้นไป</td>
              <td>
                {{ step.price.toLocaleString() }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: { product: Object },
};
</script>
<style lang="scss" scoped>
.stepPriceDetail {
  font-size: 14px;
  margin-bottom: 15px;
}

table {
  margin-top: 5px;
  width: 100%;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}
table td,
th {
  border: 1px solid #e1e1e1;
  padding: 5px;
}

table thead th {
  font-weight: bold;
}
</style>