<template>
  <div class="importProductDetail">
    <div class="nav">
      <div class="navInner">
        <!-- <img @click="back()" src="@/assets/img/left-arrow.svg" alt="" /> -->
        <div @click.stop="back()" class="backBtn mask-image"></div>

        <!-- <span class="title">นำเข้าสินค้า Thaibev</span> -->
      </div>
    </div>
    <div class="productDetail">
      <div class="imgWrap">
        <img
          v-if="product.productPhotos && product.productPhotos.length > 0"
          :src="product.productPhotos[0].url"
        />
        <img v-else :src="$placeholderImg" />
      </div>
      <div class="detailWrap">
        <div class="productTitle">{{ product.name }}</div>
        <div class="priceWrap">
          <span class="price">
            <span v-if="calStep(product)"
              >{{ calStep(product).min.toLocaleString() }} ~
              {{ calStep(product).max.toLocaleString() }}</span
            >
            <span v-else>{{ product.salePrice.toLocaleString() }}</span>
          </span>
          <span class="unitPrice">บาท</span>
          <span v-if="product.price !== product.salePrice" class="fromPrice"
            >{{ product.price }} <span class="unitPrice">บาท</span></span
          >
          <span class="unit"> / {{ product.unit }}</span>
        </div>
        <div class="productSubTitle">
          <div class="desTitle"><b>รายละเอียดสินค้า</b></div>
          {{ product.description }}
        </div>
        <StepPriceDetail :product="product" />
      </div>
      <div class="actionWrap">
        <div v-if="product.adding" class="addingBtn btn">
          <img src="@/assets/img/loader.svg" width="15" alt="" />
          กำลังเพิ่ม...
        </div>
        <div v-else>
          <div
            v-if="!product.child"
            class="addBtn btn"
            @click="addProduct(product.id)"
          >
            + เพิ่ม
          </div>
          <div v-if="product.child" class="addedBtn btn">เพิ่มแล้ว</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import StepPriceDetail from "@/components/StepPriceDetail";
import { calStep } from "@/utils/product";

export default {
  props: { product: Object },
  components: { StepPriceDetail },
  methods: {
    calStep: calStep,
    back() {
      !isEmpty(window.$forceBackTo)
        ? this.$router.push(window.$forceBackTo)
        : this.$router.back();
    },
    addProduct(id) {
      this.$emit("addProduct", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.importProductDetail {
  background: #fff;
  height: 100%;
}

.nav {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 45px;
}

.navInner {
  background: #ffffffc2;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 6px;
  border-radius: 7px;
}

.navInner > img {
  width: 25px;
}

.title {
  padding-left: 8px;
}

img {
  max-width: 100%;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.imgWrap {
  img,
  .img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 500px;
    aspect-ratio: 1/1;
  }
}

.productTitle {
  font-size: clamp(18px, 5vw, 24px);
  font-weight: bold;
  color: dimgrey;
}

.productSubTitle {
  margin: 20px 0;
  font-size: 16px;
  .desTitle {
    margin: 10px 0;
    color: #5c5c5c;
    font-size: 15px;
  }
}

.actionWrap {
  position: fixed;
  bottom: 10px;
  left: 0;
  width: 100%;
  padding: 5px 10px;
  box-sizing: border-box;
  z-index: 1;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 16px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  max-width: 400px;
  margin: 0 auto;
}

.addBtn {
  background: var(--primary-color);
  color: #fff;
}

.addedBtn {
  background: #a0a0a0;
  color: #fff;
}

.addingBtn {
  background: #afafaf;
  color: #fff;
  > img {
    padding-right: 5px;
  }
}

.priceWrap {
  margin-top: 15px;
}

.detailWrap {
  font-size: 12px;
  padding: 4px 15px 4px 15px;
  z-index: 1;
  position: relative;
  line-height: 17px;
}

.fromPrice {
  font-size: 16px;
  margin-left: 6px;
  color: #989898;
  text-decoration: line-through;
}

.price {
  font-size: 20px;
  padding-right: 4px;
  color: var(--primary-color);
}

.productDetail {
  overflow: scroll;
  height: calc(100vh - 60px);
  padding-bottom: 20px;
}

.backBtn {
  &.mask-image {
    width: 28px;
    height: 28px;
    mask-image: url("~@/assets/img/left-arrow.svg");
  }
}
</style>