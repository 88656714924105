<template>
  <div>
    <div class="item" v-for="(item, index) of data" :key="index">
      <div class="data">
        <div class="name">{{ item.name }}</div>
        <div>{{ `${item.detail}` }}</div>
        <div>
          {{
            `${item.district && item.district.name} ${
              item.province && item.province.name
            } ${item.postcode}`
          }}
        </div>
      </div>
      <div class="actionWrap">
        <div class="button" @click="click(item.id)">แก้ไข</div>
        <div class="checkDefaultWrap">
          <div v-if="!isCheckout">
            <input
              type="radio"
              @change="setDefault(item.id, $event.target.checked)"
              name="setDefault"
              :id="'setDefault_' + item.id"
              :checked="item.is_default"
            />

            <label :for="'setDefault_' + item.id">ใช้ที่อยู่นี้</label>
          </div>
          <div
            v-if="isCheckout"
            class="btn useBtn"
            @click="setDefault(item.id, true)"
          >
            ใช้ที่อยู่นี้
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    editItemClick: Function,
    setDefault: Function,
    isCheckout: Boolean,
  },
  data() {
    return {
      fetching: false,
      controller: null,
    };
  },
  methods: {
    click(item) {
      this.editItemClick && this.editItemClick(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.useBtn {
  color: #fff;
  font-size: 14px;
  background: var(--primary-color);
  padding: 7px 16px !important;
  width: fit-content;
  border-radius: 7px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.item {
  margin-bottom: 10px;
  display: flex;
  padding: 8px 12px;
  border-radius: 10px;
  border: 1px solid #d8d8d8;
  min-height: 40px;
  user-select: none;

  .data > div:not(:last-child) {
    margin-bottom: 8px;
  }

  .data {
    flex: 1;
    padding-top: 4px;
    > .name {
      font-weight: bold;
      padding-bottom: 4px;
    }
  }

  .actionWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .button {
    padding: 8px 16px;
    width: 80px;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    text-align: center;
    margin-left: auto;
  }
  .checkDefaultWrap {
    margin-top: 10px;
    padding-left: 3px;
  }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--primary-color);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
</style>