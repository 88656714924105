<template>
  <div class="userAdd">
    <NavbarBack title="เชิญผู้ใช้งานเว็บหลังบ้าน(พนักงาน)" />
    <div class="container">
      <div class="description">
        อีเมลจะถูกส่งไปยังอีเมลที่ระบุ
        กรุณากดลิงก์ในอีเมลเพื่อสร้างรหัสผ่านและผู้ใช้งาน
      </div>
      <div class="title">อีเมล*</div>
      <input v-model="email" type="text" />
    </div>
    <div class="bottom">
      <div class="button" @click="handleSave">บันทึกข้อมูล</div>
    </div>
  </div>
</template>

<script>
import { GET, POST } from "@/utils/http";

import NavbarBack from "@/components/NavbarBack";

export default {
  components: { NavbarBack },
  data() {
    return {
      email: "",
      roles: [],
    };
  },
  methods: {
    async handleSave() {
      try {
        window.loading(true);
        const reqBody = {
          email: this.email,
          shopID: this.$myShop.id,
          rolesID: this.roles,
        };
        const url = `/api/invite`;
        const { status } = await POST(url, reqBody);

        window.loading(false);
        if (status === 200) {
          await window.$alert("ส่งคำเชิญแล้ว");
          this.$router.push("/settings/user-setting");
        } else {
          await window.$alert("รูปแบบอีเมลไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง");
          return;
        }
      } catch (err) {
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
      } finally {
        window.loading(false);
      }
    },
    async getRoles() {
      try {
        const { status, body } = await GET(`/api/roles/shop`);

        if (status !== 200) {
          throw "not ok";
        }
        this.roles = body.data.map((role) => role.id);
      } catch (err) {
        console.log(err);
      }
    },
    initial() {
      this.email = "";
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.initial();
    });
  },
  mounted() {
    this.getRoles();
  },
};
</script>
<style lang="scss" scoped>
.userAdd {
  padding-top: 40px;
  background: #fff;
  height: 100%;
}
.description {
  font-style: italic;
  line-height: 28px;
  color: rgb(102, 102, 102);
  margin-bottom: 8px;
}
.container {
  padding: 0 16px;
}
.title {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
input {
  width: 100%;
  height: 42px;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #b9b9b9;
  background: #ffffffbd;
}
.bottom {
  position: absolute;
  width: 100%;
  padding: 0 16px;
  bottom: 24px;
}
.button {
  padding: 16px 0;
  text-align: center;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 8px;
  font-weight: bold;
}
.mt {
  margin-top: 8px;
}
</style>