<template>
  <div class="settingMain">
    <NavbarBack :title="isNew ? 'เพิ่มบัญชี' : 'แก้ไขบัญชี'" />

    <div v-if="isNew" class="menuBar">
      <div
        @click="clickMenu('transfer')"
        :class="{ active: this.data.slug == 'transfer' }"
      >
        บัญชีธนาคาร
      </div>
      <div
        @click="clickMenu('promptpay')"
        :class="{ active: this.data.slug == 'promptpay' }"
      >
        พร้อมเพย์
      </div>
    </div>

    <form
      id="form_transfer"
      v-if="this.data.slug == 'transfer'"
      class="container"
    >
      <label>ธนาคาร*</label>
      <select
        v-model="data.bankId"
        :disabled="banks_list.length == 0"
        required
        @change="
          (e) => {
            this.data.bankId = parseInt(e.target.value);
          }
        "
      >
        <option disabled :value="null">เลือกธนาคาร</option>
        <option v-for="banks in banks_list" :key="banks.id" :value="banks.id">
          {{ banks.name }}
        </option>
      </select>

      <label>สาขา*</label>
      <input
        v-model="data.branchName"
        required
        type="text"
        placeholder="สาขา"
      />

      <label>ชื่อบัญชี*</label>
      <input
        v-model="data.accountName"
        required
        type="text"
        placeholder="ชื่อบัญชี"
      />

      <label>เลขที่บัญชี*</label>
      <input
        v-model="data.accountNo"
        required
        type="text"
        placeholder="เลขที่บัญชี"
      />
    </form>

    <form
      id="form_promptpay"
      v-if="this.data.slug == 'promptpay'"
      class="container"
    >
      <label>เบอร์มือถือ/เลขบัตรประชาชน/เลขประจำตัวผู้เสียภาษี*</label>
      <input
        v-model="data.accountNo"
        required
        type="text"
        placeholder="เลขที่บัญชี"
      />

      <label>ชื่อบัญชี*</label>
      <input
        v-model="data.accountName"
        required
        type="text"
        placeholder="ชื่อบัญชี"
      />
    </form>

    <div class="bottom">
      <div v-show="!isNew" class="button outline danger" @click="onDeleteClick">
        ลบข้อมูล
      </div>
      <div class="button" @click="onSaveClick">บันทึกข้อมูล</div>
    </div>
  </div>
</template>

<script>
import { GET, POST, PUT, DELETE } from "@/utils/http";

import NavbarBack from "@/components/NavbarBack";

export default {
  components: { NavbarBack },
  props: {
    id: Number,
    original_data: Object,
  },
  data() {
    return {
      data: {
        bankId: null,
        accountName: "",
        accountNo: "",
        branchName: "",
        slug: "transfer",
      },
      fetching: false,
      error: false,
      banks_list: [],
    };
  },
  methods: {
    clickMenu(menu) {
      this.data.slug = menu;
    },
    async fetchBanks() {
      try {
        this.fetching = true;
        const { status, body } = await GET(`/api/banks`);

        if (status !== 200) {
          throw "not ok";
        }

        this.banks_list = body;
      } catch (err) {
        this.error = true;
      } finally {
        this.fetching = false;
      }
    },
    async onDeleteClick() {
      if (!(await window.$alert("ยืนยันการลบ?", "confirm"))) {
        return;
      }
      await DELETE(
        `/api/shops/${this.$myShop.id}/payment_methods/details/${this.id}`
      );
      this.$router.back();
    },
    async onSaveClick() {
      const form = document.getElementById("form_" + this.data.slug);

      if (!form.checkValidity()) {
        window.$alert("กรุณากรอกข้อมูลให้ครบ");
        return;
      }

      if (!(await window.$alert("ยืนยันการบันทึก?", "confirm"))) {
        return;
      }
      const url = `/api/shops/${this.$myShop.id}/payment_methods/${this.data.slug}/details`;
      const reqBody = {
        ...this.data,
      };
      if (this.isNew) {
        await POST(url, reqBody);
      } else {
        await PUT(`${url}/${this.id}`, reqBody);
      }
      this.$router.back();
    },
    init() {
      if (this.original_data != null) {
        this.data = this.original_data;
      } else {
        this.data = {
          bankId: null,
          accountName: "",
          accountNo: "",
          branchName: "",
          slug: "transfer",
        };
      }
    },
  },
  computed: {
    isNew() {
      return this.id == null;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.init();
    });
  },
  mounted() {
    this.fetchBanks();
  },
};
</script>
<style lang="scss" scoped>
.settingMain {
  padding-top: 40px;
  background: #fff;
  height: 100%;
}
.container {
  margin-top: 8px;
  padding: 0 16px;
}
label {
  display: flex;
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: bold;
}
input,
textarea {
  font-family: "Sarabun", sans-serif;
  width: 100%;
  min-height: 40px;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #b9b9b9;
  background: #ffffffbd;
  resize: none;
}
.bottom {
  position: absolute;
  width: 100%;
  padding: 0 16px;
  bottom: 24px;
  > .button:not(:last-child) {
    margin-bottom: 8px;
  }
}
.button {
  padding: 16px 0;
  text-align: center;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 8px;
  font-weight: bold;

  &.outline {
    border: 1px solid #cccccc;
    background-color: #fff;
    color: #434343;
  }
  &.danger {
    color: #da291c;
  }
}

select {
  width: 100%;
  padding: 9px 4px;
  /* padding-right: 16px; */
  font-size: 13pt;
  border-radius: 4px;
  background: white;
  border-color: #b9b9b9;
}

.menuBar {
  user-select: none;
  display: flex;
  background: #ffffff;
  justify-content: center;
  // align-items: center;
  width: 100%;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
  > div {
    width: 100%;
    text-align: center;
    color: #55585b; //rgb(131, 131, 131);
    font-size: clamp(12px, 3.5vw, 13px);
    padding: 3px 2px 10px 2px;
    display: inline-block;
    border-radius: 8px;
    margin: 0 4px;
    cursor: pointer;
    // height: 30px;
    &.active {
      position: relative;
      // color: #ffffff; // rgb(112, 112, 112);
      // border-color: rgb(218, 218, 218);
      // background: var(--primary-color); //rgb(218, 218, 218);
    }
  }
}

.active:after {
  content: "";
  width: 80%;
  border-bottom: 2px solid var(--primary-color) !important;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  left: 50%;
}
</style>