var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.products.length > 0)?_c('div',{staticClass:"productListHorizon"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"productList"},_vm._l((_vm.products),function(item,index){return _c('div',{key:index,staticClass:"card",on:{"click":function($event){return _vm.showProduct(item)}}},[_c('div',{class:[
          _vm.productCompanyGroupName(item),
          { showBrandFooter: item.parentId },
          'imgWrap',
        ]},[(item.addons)?_c('div',{staticClass:"strip"},[_c('span',[_vm._v("โปรโมชั่น")])]):_vm._e(),(item.productPhotos)?_c('img',{attrs:{"src":item.productPhotos.length > 0
              ? item.productPhotos[0].url
              : _vm.$placeholderImg}}):_c('img',{attrs:{"src":_vm.$placeholderImg}})]),_c('div',{staticClass:"detailWrap"},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"priceWrap"},[_c('span',{staticClass:"price"},[(_vm.calStep(item))?_c('span',[_vm._v(_vm._s(_vm.calStep(item).min.toLocaleString())+" ~ "+_vm._s(_vm.calStep(item).max.toLocaleString()))]):_c('span',[_vm._v(_vm._s(item.salePrice.toLocaleString()))])]),_c('span',{staticClass:"unitPrice"},[_vm._v("บาท")]),(item.price !== item.salePrice)?_c('span',{staticClass:"fromPrice"},[_vm._v(_vm._s(item.price)+" "),_c('span',{staticClass:"unitPrice"},[_vm._v("บาท")])]):_vm._e(),_c('span',{staticClass:"unit"},[_vm._v(" / "+_vm._s(item.unit))])])]),(_vm.$isBuyer)?_c('div',{staticClass:"actionContain"},[(item.product_status !== 'สินค้าหมด')?_c('div',{staticClass:"actionWrap"},[_c('div',{staticClass:"buyBtn",on:{"click":function($event){$event.stopPropagation();return _vm.clickAddToCart({ product: item })}}},[_vm._v(" เพิ่มสินค้า ")])]):_vm._e()]):_vm._e()])}),0),(_vm.fetching)?_c('ProductListHorizonLoader'):_vm._e(),_c('DefaultDisplayStage',{attrs:{"error":_vm.error,"nodata":_vm.nodata}}),_c('AlertAddCart',{ref:"alertAddCart"})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }